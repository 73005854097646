import React, { useState } from "react";
import { iconDemo } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import enhancer from "./enhancer/LoginFormEnhancer";
import { signIn } from "../../../api/index";
import { login } from "../../../redux/auth/actions";
import CustomToast from "../../../components/customToast/CustomToast";

const Login = (props) => {
  const [toast, setCustomToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    let { values, handleSubmit } = props;
    handleSubmit();
    if (props.isValid) {
      const data = {
        email: values.email,
        password: values.password,
      };
      signIn(data).then((res) => {
        if (res && res.status) {
          props.login(res.data);
          props.history.push("/dashboard");
        } else {
          if (res) {
            setToastMessage(res.message);
            setCustomToast(true);
          }
        }
      });
    }
  };

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
  } = props;

  const loginContainer = {
    // backgroundImage: `url(${loginBack})`,

    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
    // background: "rgba(156, 214, 236, 1)",
    background: "linear-gradient(45deg,#46b3c3,#0E8EA4,#115E67)",
  };

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      {toast && (
        <CustomToast
          heading={"Alert!"}
          width={400}
          show="bottomRight"
          transition
          position="bottom-right"
          className="c-danger"
          message={toastMessage}
          onCloseCLick={() => setCustomToast(false)}
        />
      )}
      <div className="form-container">
        <div className="login-icon">
          <img src={iconDemo} alt="icon" height="100px" />
        </div>
        <div className="login-title">Sign in</div>
        <form className="pa-24" onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control react-form-input"
              id="email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur}
              placeholder="Email"
            />
            <Error field="email" />
          </div>

          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-control react-form-input"
              id="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Password"
            />
            <Error field="password" />
          </div>

          <button type="submit" className="btn form-button">
            Login
          </button>
          <div
            className="text-center link-label"
            onClick={() => props.history.push("/forgotPassword")}
          >
            Forgot Password ?
          </div>
        </form>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(login(data)),
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(null, mapDispatchToProps)
)(Login);
