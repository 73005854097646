import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Collapse, CardBody, Card } from "reactstrap";
import IntlMessages from "util/intlMessages";
import classNames from "classnames";

class NavSecondChild extends Component {
  state = {
    collapse: false,
  };

  toggle = (e) => {
    e.preventDefault();
    this.setState({ collapse: !this.state.collapse });
  };

  componentDidMount = () => {
    if (
      this.props.childList.child
        .map((a) => a.routepath)
        .includes(this.props.CurrentRoute)
    ) {
      this.setState({ collapse: true });
    }
  };

  render() {
    const {
      listNameStyle,
      childList,
      mini,
      drawerWidth,
      miniDrawerWidth,
      CurrentRoute,
    } = this.props;
    const { collapse } = this.state;

    return (
      <ul className="pa-0">
        <li className="pos-relative">
          {!mini || miniDrawerWidth === drawerWidth ? (
            collapse ? (
              <i
                className="fas fa-angle-up arrow-sidebar second-child-list-icon"
                onClick={this.toggle}
              />
            ) : (
              <i
                className="fas fa-angle-down arrow-sidebar second-child-list-icon"
                onClick={this.toggle}
              />
            )
          ) : (
            ""
          )}
          <NavLink
            to={"demo"}
            onClick={this.toggle}
            className={classNames(
              childList.child
                .map((a) => a.routepath)
                .includes(`/${CurrentRoute.split("/")[1]}`) &&
                "active-sub-parent",
              "nav-link",
              "main-list"
            )}
          >
            <i className={childList.iconClass} />
            <p style={listNameStyle}>{childList.listname}</p>
          </NavLink>
          <Collapse isOpen={this.state.collapse}>
            <Card className="background-transparent border-none">
              <CardBody className="pa-0">
                {childList.child
                  ? childList.child.map((listData, i) => {
                      return (
                        <NavLink
                          to={listData.routepath}
                          className="nav-link child-list"
                          key={i}
                        >
                          <span className="span-shortname">
                            {listData.shortname}
                          </span>
                          <p style={listNameStyle}>{listData.listname}</p>
                        </NavLink>
                      );
                    })
                  : ""}
              </CardBody>
            </Card>
          </Collapse>
        </li>
      </ul>
    );
  }
}

export default NavSecondChild;
