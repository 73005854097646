import React from "react";
import "./modal.css";
import Backdrop from "../backdrop/backdrop";
import { ForgotIcon } from "../../helper/constant";
import enhancer from "./modalEnhancer";
import {
  changePassword,
  subadminchangePassword,
  resetPassword,
} from "../../api/index";
import CustomToast from "../customToast/CustomToast";

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      msg: "",
      className: "",
    };
  }
  render() {
    const Error = (props) => {
      const { errors, touched, submitCount } = this.props;
      const field1 = props.field;
      if ((errors[field1] && touched[field1]) || submitCount > 0) {
        return (
          <span className={props.class ? props.class : "error-msg"}>
            {errors[field1]}
          </span>
        );
      } else {
        return <span />;
      }
    };
    const submitPassword = (e) => {
      e.preventDefault();
      this.props.handleSubmit();

      if (this.props.isValid) {
        const data = {
          newpassword: this.props.values.passwordNew,
          oldpassword: this.props.values.passwordOld,
        };
        if (this.props.isSuper) {
          resetPassword(data).then((res) => {
            if (res && res.status === 1) {
              this.props.showSuccess();
            } else {
              if (res) {
                this.setState({
                  show: true,
                  msg: res.message,
                  className: "c-danger",
                });
              }
            }
          });
        } else {
          if (localStorage.getItem("user_type") === "company") {
            changePassword(data).then((res) => {
              if (res && res.status === 1) {
                this.props.showSuccess();
              } else {
                if (res) {
                  this.setState({
                    show: true,
                    msg: res.message,
                    className: "c-danger",
                  });
                }
              }
            });
          } else {
            if (localStorage.getItem("user_type") === "subadmincompany") {
              subadminchangePassword(data).then((res) => {
                if (res && res.status === 1) {
                  this.props.showSuccess();
                } else {
                  if (res) {
                    this.setState({
                      show: true,
                      msg: res.message,
                      className: "c-danger",
                    });
                  }
                }
              });
            }
          }
        }
      }
    };
    let Alert = <span></span>;

    if (this.state.show) {
      Alert = (
        <CustomToast
          heading={"Alert!"}
          width={400}
          show="bottomRight"
          transition
          position="bottom-right"
          className={this.state.className}
          message={this.state.msg}
          onCloseCLick={() => this.setState({ show: false })}
        />
      );
    }

    return (
      <React.Fragment>
        {Alert}
        <Backdrop clickHandler={this.props.closeModal} show={this.props.show} />
        <div className="Modal form-container">
          <div className="login-icon">
            <img src={ForgotIcon} alt="icon" height="100px" />
          </div>
          <div className="login-title">Change Password</div>
          <form className="pa-24" onSubmit={submitPassword}>
            <div className="form-group">
              <input
                type="password"
                className="form-control react-form-input"
                id="passwordOld"
                name="passwordOld"
                value={this.props.values.passwordOld}
                onChange={this.props.handleChange}
                placeholder="Old Password"
              />
              <Error field="passwordOld" />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control react-form-input"
                id="passwordNew"
                name="passwordNew"
                value={this.props.values.passwordNew}
                onChange={this.props.handleChange}
                onBlur={this.props.handleBlur}
                placeholder="New Password"
              />
              <Error field="passwordNew" />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control react-form-input"
                id="passwordConfirm"
                name="passwordConfirm"
                value={this.props.values.passwordConfirm}
                onChange={this.props.handleChange}
                onBlur={this.props.handleBlur}
                placeholder="Confirm Password"
              />
              <Error field="passwordConfirm" />
            </div>
            <div className="form-group">
              <button type="submit" className="btn form-button">
                Confirm New Password
              </button>
            </div>
            <div
              className="text-center link-label"
              onClick={this.props.closeModal}
            >
              Cancel
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default enhancer(Modal);
