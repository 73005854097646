import * as actions from "./actionTypes";
export const login = (data) => {
  return {
    type: actions.LOGIN,
    accessToken: data.accessToken,
    isSuper: data.super,
    isEdit: data.isEdit,
    user_type: data.details.user_type,
    details: data.details,
  };
};

export const logout = () => {
  return {
    type: actions.LOGOUT,
  };
};

export const changeDetails = (data) => {
  return {
    type: actions.CHANGE_DETAILS,
    details: data,
  };
};
