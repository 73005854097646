import * as actionTypes from "./actionTypes";

const initState = {
  isLogin: localStorage.getItem("isLogin")
    ? localStorage.getItem("isLogin") === "true"
    : false,
  accessToken: localStorage.getItem("accessToken"),
  isSuper: localStorage.getItem("isSuper") === "true" ? true : false,
  details: localStorage.getItem("details")
    ? JSON.parse(localStorage.getItem("details"))
    : {},
  user_type: localStorage.getItem("user_type"),
  isEdit: localStorage.getItem("isEdit"),
  isEdit:
    localStorage.getItem("isSuper") === "true"
      ? localStorage.getItem("isEdit")
      : null,
};

const rootReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        isLogin: true,
        accessToken: action.accessToken,
        isSuper: action.isSuper,
        isEdit: action.isEdit,
        details: action.details,
        user_type: action.user_type,
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        isLogin: false,
        accessToken: null,
        isSuper: false,
        isEdit: null,
        details: { id: "1" },
        user_type: "none",
      };
    case actionTypes.CHANGE_DETAILS:
      return {
        ...state,
        details: action.details,
        user_type: action.details.user_type,
      };

    default:
      return state;
  }
};

export default rootReducer;
