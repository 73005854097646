import React from "react";
import UserWrapper from "./user.style";
import PageTitle from "components/common/PageTitle";
import UserFromSACTable from "../../components/userModule/UserFromSACTable";
import { connect } from "react-redux";

const UserFromSAC = (props) => {
  return (
    <UserWrapper {...props}>
      <div className="pos-relative">
        <PageTitle title="users" className="plr-15" iconClass="fas fa-user" />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/companies")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="user-tbl">
        <UserFromSACTable {...props} />
      </div>
    </UserWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
  };
};

export default connect(mapStateToProps, null)(UserFromSAC);
