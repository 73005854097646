import Layout from "layouts/DashboardLayout.jsx";
import { Login, ForgotPassword } from "views/pages/index";

const indexRoutes = [
  { path: "/login", component: Login },
  { path: "/forgotPassword", component: ForgotPassword },
  { path: "/", component: Layout }
];

export default indexRoutes;
