import React, { useState } from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import enhancer from "./EditProfileEnhancer";
import ProfileImage from "./profileImage/ProfileImage";
import { editProfile } from "../../api/index";
import { changeDetails } from "../../redux/auth/actions";
import { connect } from "react-redux";

const AWS_URL = process.env.REACT_APP_AWS_URL;

const EditProfile = props => {
  const [logo, setlogo] = useState();

  const getImgObj = imgObject => {
    // console.log("IMAGEOBJECT", imgObject, typeof imgObject);
    // console.log(window.URL.createObjectURL(imgObject));
    var reader = new FileReader();
    reader.readAsDataURL(imgObject);
    reader.onload = function() {
      setlogo(reader.result);
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  };

  const handleSubmit = e => {
    e.preventDefault();

    props.handleSubmit();
    const err = Object.getOwnPropertyNames(props.errors);
    if (props.isValid || !err.length) {
      let { values } = props;
      const submitData = {
        ...values,
        isComplex: props.companyDetails.isComplex,
        logo: logo ? logo : values.logo
      };
      editProfile(submitData).then(res => {
        if (res && res.status) {
          props.changeDetails(res.data);
          props.toggle();
        }
      });
    }
  };
  const { errors, touched, submitCount } = props;
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Edit Profile</ModalHeader>
      <ModalBody>
        <form>
          <ProfileImage
            center={true}
            getImgObj={getImgObj}
            url={`${AWS_URL}${props.companyDetails.logo}`}
          />
          <div className="form-group">
            <label className="fs-16 medium-text">Name</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.name}
              placeholder="Name"
            />
            <Error field="name" />
          </div>
          <div className="form-group">
            <label className="fs-16 medium-text">Email</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="email"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.email}
              placeholder="Enter Email"
            />
            <Error field="email" />
          </div>
          <div className="form-group">
            <label className="fs-16 medium-text">Address</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="address"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.address}
              placeholder="Address"
            />
            <Error field="address" />
          </div>
          <div className="form-group">
            <label className="fs-16 medium-text">Description</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="description"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.description}
              placeholder="Enter Description"
            />
            <Error field="description" />
          </div>
          <div className="form-group">
            <label className="fs-16 medium-text">Company Link</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="link"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.link || ""}
              placeholder="Enter Link"
            />
            <Error field="link" />
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <div>
          <button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={handleSubmit}>
            Submit
          </button>
        </div>
        <div>
          <button
            type="button"
            className="c-btn c-danger form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => props.toggle()}>
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
const mapDispatchToProps = dispatch => {
  return {
    changeDetails: data => dispatch(changeDetails(data))
  };
};

export default connect(null, mapDispatchToProps)(enhancer(EditProfile));
