import React, { useState, useEffect } from "react";
import DashboardWrapper from "../dashboard.style";
import { Container, Row, Col } from "reactstrap";
import Card from "../Card/Card";
import {
  viewStatistics,
  viewStatisticsSAC,
  viewSAC_ID,
} from "../../../api/index";

const DashboardCards = () => {
  const [counts, setCounts] = useState({
    user: 0,
  });
  const [subadminscounts, setsubadminscounts] = useState({
    subadmins: 0,
  });

  const [company_id, setCompany] = useState({
    company_id: 0,
  });

  useEffect(() => {
    viewStatisticsSAC().then((res) => {
      if (res && res.status) {
        const Userdata = {
          ...counts,
          user: res.data.list.users,
        };
        const Companydata = {
          ...company_id,
          company_id: res.data.list.company_id,
        };
        const SubAdmindata = {
          ...subadminscounts,
          subadmins: res.data.list.subadmins,
        };
        setCounts(Userdata);
        setCompany(Companydata);
        setsubadminscounts(SubAdmindata);
      } else {
        setCounts(counts);
        setCompany(company_id);
        setsubadminscounts(subadminscounts);
      }
    });

    // viewSAC_ID().then((res) => {
    //   if (res && res.status) {
    //     setCompany({ company_id: res.data.list.company });
    //     console.log(res.data.list.company);
    //   }
    // });
  }, []);

  return (
    <DashboardWrapper>
      <Container fluid>
        <Row>
          <Col xs={12} lg={4} md={4} sm={4}>
            <Card
              title="Sub Admins"
              count={subadminscounts.subadmins}
              icon="fas fa-user-alt"
              bgColor="#0E8EA4"
              to="/sacfromsubadmincompanies"
            ></Card>
          </Col>
          <Col xs={12} lg={4} md={4} sm={4}>
            <Card
              title="Users"
              count={counts.user}
              icon="fas fa-user-alt"
              bgColor="#0E8EA4"
              to="/usersfromsubadmincompanies"
            ></Card>
          </Col>
        </Row>
      </Container>
    </DashboardWrapper>
  );
};

export default DashboardCards;
