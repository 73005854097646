import { withFormik } from "formik";
import * as Yup from "yup";

const regEx = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

const emailValidator = withFormik({
  validationSchema: Yup.object().shape({
    passwordNew: Yup.string()
      .required("This Field is required")
      .min(8, "password must contain atleast 8 characters")
      .matches(regEx, "password must contain a special character and a number"),
    passwordOld: Yup.string().required("This Field is required"),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("passwordNew"), null], "Password doesn't match")
      .required("Required")
  }),
  mapPropsToValues: props => ({
    passwordNew: "",
    passwordOld: "",
    passwordConfirm: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default emailValidator;
