import { withFormik } from "formik";
import * as Yup from "yup";

const regEx = /\S+@\S+\.\S+/;

const EditProfileEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string().required("This field is required"),
    address: Yup.string().required("This field is required"),
    email: Yup.string()
      .matches(regEx, "This doesn't look like an Email")
      .required("This Field is required")
  }),
  mapPropsToValues: props => ({
    name: props.companyDetails.name,
    address: props.companyDetails.address,
    email: props.companyDetails.email,
    description: props.companyDetails.description,
    link: props.companyDetails.link,
    logo: props.companyDetails.logo
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default EditProfileEnhancer;
