import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { routerReducer, routerMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";
import reducers from "../redux/reducers";

const history = createBrowserHistory({
  basename: `${process.env.REACT_APP_BASENAME === 'true' ? "safecide-cms" : "" }`
});
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, routeMiddleware];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  combineReducers({
    ...reducers,
    router: routerReducer
  }),
  composeEnhancer(applyMiddleware(...middlewares))
);

store.subscribe(() => {
  localStorage.setItem("accessToken", store.getState().auth.accessToken);
  localStorage.setItem("isLogin", store.getState().auth.isLogin);
  localStorage.setItem("isSuper", store.getState().auth.isSuper);
  localStorage.setItem("isEdit", store.getState().auth.isEdit);
  
  localStorage.setItem(
    "details",
    JSON.stringify(store.getState().auth.details)
  );
  localStorage.setItem(
    "user_type",
    store.getState().auth.details.user_type
  );
  
});

export { store, history };
