import Users from "views/UserModule/User";
import UserEdit from "views/UserModule/UserEdit";
import Dashboard from "../views/Dashboard/Dashboard";
import Company from "../views/CompanyModule/Company";
import CompanyEdit from "../views/CompanyModule/CompanyEdit";
import Report from "../views/ReportModule/Report";

import AllUsersFromAdminDashboard from "../views/UserModule/AllUsersFromAdminDashboard";
import AllSACFromAdminDashboard from "../views/SubAdminCompanyModule/AllSACFromAdminDashboard";

import SubAdminCompany from "../views/SubAdminCompanyModule/SubAdminCompany";
import SubAdminCompanyFromAdmin from "../views/SubAdminCompanyModule/SubAdminCompanyFromAdmin";

import UserFromAdmin from "../views/UserModule/UserFromAdmin";
import UserFromAdminEdit from "../views/UserModule/UserFromAdminEdit";

import SACFromSAC from "../views/SACFromSACModule/SACFromSAC";
import SACFromSACEdit from "../views/SACFromSACModule/SACFromSACEdit";

import UserFromSAC from "views/UserFromSACModule/UserFromSAC";
import UserFromSACEdit from "views/UserFromSACModule/UserFromSACEdit";

import SubAdminCompanyEdit from "../views/SubAdminCompanyModule/SubAdminCompanyEdit";
import SubAdminCompanyFromAdminEdit from "../views/SubAdminCompanyModule/SubAdminCompanyFromAdminEdit";

const dashboardRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/report", component: Report },

  { path: "/users", component: Users },
  { path: "/users/add", component: UserEdit },
  { path: "/users/edit/:id", component: UserEdit },

  {
    path: "/allusersfromadmindashboard",
    component: AllUsersFromAdminDashboard,
  },
  {
    path: "/allsacfromadmindashboard",
    component: AllSACFromAdminDashboard,
  },

  { path: "/companies", component: Company },
  { path: "/companies/add", component: CompanyEdit },
  { path: "/companies/edit/:id", component: CompanyEdit },

  { path: "/subadmincompanies", component: SubAdminCompany },

  { path: "/sacfromsubadmincompanies", component: SACFromSAC },
  { path: "/usersfromsubadmincompanies", component: UserFromSAC },

  {
    path: "/subadmincompaniesfromadmin/:company_id",
    component: SubAdminCompanyFromAdmin,
  },

  {
    path: "/viewusersfromadmin/:company_id",
    component: UserFromAdmin,
  },
  {
    path: "/editusersfromadmin/edit/:id/:company_id",
    component: UserFromAdminEdit,
  },

  {
    path: "/addusersfromadmin/add/:company_id",
    component: UserFromAdminEdit,
  },

  { path: "/usersfromsac/add", component: UserFromSACEdit },
  {
    path: "/usersfromsac/edit/:id",
    component: UserFromSACEdit,
  },

  { path: "/subadmincompaniesfromsac/add", component: SACFromSACEdit },
  { path: "/subadmincompaniesdata/add", component: SubAdminCompanyEdit },
  {
    path: "/subadmincompaniesdata/add/:company_id",
    component: SubAdminCompanyEdit,
  },
  {
    path: "/subadmincompaniesdata/edit/:company_id/:id",
    component: SubAdminCompanyEdit,
  },
  {
    path: "/subadmincompaniesdatafromadmin/add",
    component: SubAdminCompanyFromAdminEdit,
  },
  {
    path: "/subadmincompaniesdatafromadmin/add/:company_id",
    component: SubAdminCompanyFromAdminEdit,
  },
  {
    path: "/subadmincompaniesdatafromadmin/edit/:company_id/:id",
    component: SubAdminCompanyFromAdminEdit,
  },

  {
    path: "/subadmincompaniesfromsac/edit/:id",
    component: SACFromSACEdit,
  },
];

export default dashboardRoutes;
