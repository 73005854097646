import React from "react";
import PageTitle from "../../components/common/PageTitle";
import SuperDashboard from "../../components/Dashboard/Super/dashboard";
import CompanyDashboard from "../../components/Dashboard/Company/dashboard";
import SubAdminCompanyDashboard from "../../components/Dashboard/SubAdminCompany/dashboard";

import { connect } from "react-redux";

const Dashboard = (props) => {
  const user = props.user_type;

  return (
    <React.Fragment>
      <PageTitle
        iconClass="fas fa-tachometer-alt"
        title="Dashboard"
        className="plr-15"
      />
      {/* {props.isSuper ? <SuperDashboard /> : <CompanyDashboard />} */}

      {props.isSuper ? (
        <SuperDashboard />
      ) : props.user_type === "company" ? (
        <CompanyDashboard />
      ) : props.user_type === "subadmincompany" ? (
        <SubAdminCompanyDashboard />
      ) : (
        ""
      )}

      {/* {props.isSuper ? <SuperDashboard /> : props.user_type === "company" ? <CompanyDashboard /> : <SubAdminCompanyDashboard />} */}

      {/* { if (props.user_type === "admin") {
        <SuperDashboard /> 
      } else if (props.user_type === "company") {
        <CompanyDashboard />
      } else if (props.user_type === "subadmincompany") {
        <SubAdminCompanyDashboard />
      }
      } */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isSuper: state.auth.isSuper,
    isEdit: state.auth.isEdit,
    user_type: state.auth.details.user_type,
  };
};

export default connect(mapStateToProps, null)(Dashboard);
