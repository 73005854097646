import React, { useState, useEffect } from "react";
import ReactTable from "react-table-6";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table-6/react-table.css";
import swal from "sweetalert";
import { withRouter } from "react-router";
import {
  viewAllSubAdminCompanies,
  deleteSubAdminCompany,
} from "../../api/index";
import { getPagination } from "../../helper/constant";
import { iconDemo } from "helper/constant";
import ViewSubAdminCompanyModal from "./viewSubAdminCompanyModal";

let debounceTimer;
const AWS_URL = process.env.REACT_APP_AWS_URL;

const SubAdminCompanyTable = (props) => {
  const hasCompany_ID = props.match.params.hasOwnProperty("company_id");
  let company_id_number;
  if (hasCompany_ID) {
    company_id_number = props.match.params.company_id;
  } else {
    company_id_number = undefined;
  }

  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pages, setPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [params, setParams] = useState({
    orderBy: "createdAt",
    orderSequence: "DESC",
    pageNo: 0,
    pageSize: 8,
    search: "",
  });

  const [subadmincompanyDetails, setsubadmincompanyDetails] = useState({});
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const {
    sidebarTheme: { activeColor, textColor },
  } = props;

  useEffect(() => {
    if (searchText !== null) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
      debounceTimer = setTimeout(callSearchApi, 500);
    }
  }, [searchText]);

  useEffect(() => {
    viewAllSubAdminCompanies(params, company_id_number).then(async (res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = await getPagination(
          res.data.totalRecords,
          params.pageSize
        );
        setPages(pages);
      }
    });
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
    };
  }, []);

  const formAction = (action, data = null) => {
    if (action === "add") {
      props.history.push(`/subadmincompaniesdata/add`);
    } else if (action === "edit") {
      props.history.push(
        `/subadmincompaniesdata/edit/${company_id_number}/${data.id}`,
        {
          ...data,
        }
      );
    }
  };

  const getUserList = () => {
    viewAllSubAdminCompanies(params, company_id_number).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
      }
    });
  };

  const onPageChangeHandler = (index) => {
    let page = index;
    setActivePage(page + 1);
    let data = {
      ...params,
      pageNo: page,
    };
    setParams(data);
    viewAllSubAdminCompanies(data, company_id_number).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
      }
    });
  };

  const callSearchApi = () => {
    const data = {
      ...params,
      search: searchText,
    };
    setParams(data);
    viewAllSubAdminCompanies(data, company_id_number).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
      }
    });
  };

  const deleteOneCompany = (subadmincompanyId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteSubAdminCompany(subadmincompanyId, company_id_number).then(
          (res) => {
            if (res && res.status) {
              swal(res.message, {
                icon: "success",
              });
              viewAllSubAdminCompanies(params, company_id_number).then(
                (res) => {
                  if (res && res.status) {
                    settblData(res.data.list);
                    setLoader(false);
                    const pages = getPagination(
                      res.data.totalRecords,
                      params.pageSize
                    );
                    setPages(pages);
                  }
                  if (res && !res.data.list.length) {
                    onPageChangeHandler(params.pageNo - 1);
                  }
                }
              );
              getUserList();
            } else {
              if (res) {
                swal(res.message, {
                  icon: "danger",
                });
              }
            }
          }
        );
      }
    });
  };

  const onSortChange = (sortProperties) => {
    const orderByDirection = sortProperties[0].desc ? 1 : 0;
    if (!orderByDirection) {
      let data = {
        ...params,
        orderBy: sortProperties[0].id,
        orderSequence: "ASC",
      };
      setParams(data);
      viewAllSubAdminCompanies(data, company_id_number).then((res) => {
        if (res && res.status) {
          settblData(res.data.list);
          setLoader(false);
        }
      });
    } else {
      let data = {
        ...params,
        orderSequence: "DESC",
      };
      setParams(data);
      viewAllSubAdminCompanies(data, company_id_number).then((res) => {
        if (res && res.status) {
          settblData(res.data.list);
          setLoader(false);
        }
      });
    }
  };

  const showOneUser = (data) => {
    setsubadmincompanyDetails(data);
    setModal(true);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Logo",
        accessor: "logo",
        className: "text-center",
        headerClassName: "react-table-header-class ",
        filterable: false,
        sortable: false,
        width: 80,
        Cell: (props) => (
          <div className="react-action-class">
            <img
              height="30px"
              src={
                props.original.logo
                  ? `${AWS_URL}${props.original.logo}`
                  : iconDemo
              }
              alt="profile"
            />
          </div>
        ), // Custom cell components!
      },
      {
        Header: "Name",
        accessor: "name",
        className: "text-left",
        filterable: false,
        headerClassName: "react-table-header-class -sort",
      },
      {
        Header: "Email",
        accessor: "email",
        className: "text-left",
        filterable: false,
        sortable: false,
        headerClassName: "react-table-header-class",
      },
      {
        Header: "SAC Level",
        accessor: "sub_admin_company_level",
        className: "text-center",
        filterable: false,
        headerClassName: "react-table-header-class",
        width: 80,
      },
      {
        Header: "Company Created",
        accessor: "createdAt",
        className: "text-center",
        filterable: false,
        headerClassName: "react-table-header-class -sort",
        Cell: (props) => {
          return `${new Date(props.original.createdAt).getMonth() +
            1}/${new Date(props.original.createdAt).getDate()}/${new Date(
            props.original.createdAt
          ).getFullYear()}`;
        },
        width: 180,
      },
      {
        Header: "Users",
        accessor: "User",
        className: "text-center",
        filterable: false,
        headerClassName: "react-table-header-class",
        width: 80,
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        headerClassName: "react-table-header-class",
        sortable: false,
        filterable: false,
        width: 300,
        Cell: (props) => (
          <div className="react-action-class">
            <Button
              className="c-btn c-info mr-10"
              onClick={() => showOneUser(props.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-eye mr-6" /> View
              </div>
            </Button>
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", props.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-edit mr-6" /> Edit
              </div>
            </Button>
            <Button
              className="c-btn c-danger"
              onClick={() => deleteOneCompany(props.original.id)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-trash mr-6" /> Delete
              </div>
            </Button>
          </div>
        ), // Custom cell components!
      },
    ],
    [params]
  );

  return (
    <React.Fragment>
      <ViewSubAdminCompanyModal
        subadmincompanyDetails={subadmincompanyDetails}
        toggle={toggle}
        modal={modal}
      />
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              {/* <span className="hash"># </span> Sub Admin Companies */}
            </div>
            <div className="mr-10">
              <input
                value={searchText || ""}
                onChange={(e) => setSearchText(e.target.value)}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            <div>
              <Button
                className="c-btn ma-5"
                onClick={() => formAction("add")}
                style={{
                  backgroundColor: activeColor,
                  color: textColor,
                }}
              >
                <i className="fas fa-plus mr-10"></i>Add Sub Admin Company
              </Button>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={2}
              minRows={0}
              pages={pages}
              filterable={false}
              manual
              className="-striped -highlight custom-react-table-theme-class"
              page={activePage - 1}
              PaginationComponent={Pagination}
              loading={loader}
              onPageChange={(pageIndex) => onPageChangeHandler(pageIndex)}
              onSortedChange={(sortProperties) => onSortChange(sortProperties)}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SubAdminCompanyTable);
