import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import styled from "styled-components";
import { iconDemo } from "../../helper/constant";

const Wrapper = styled.div`
  .key {
    font-family: montserrat-bold;
    font-size: 16px;
    overflow-wrap: break-word;
  }
  .value {
    overflow-wrap: break-word;
  }
`;

const IMGURL = process.env.REACT_APP_AWS_URL;

const viewCompanyModal = (props) => {
  const { modal, toggle, companyDetails } = props;
  return (
    <Wrapper>
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <div>
            <h6>
              <strong>View Company</strong>
            </h6>
          </div>
        </ModalHeader>
        <ModalBody>
          <Wrapper>
            <div className="text-center mb-15">
              <img
                className="img-thumbnail "
                style={{ width: "200px", height: "200px" }}
                src={
                  companyDetails.logo
                    ? `${IMGURL}${companyDetails.logo}`
                    : iconDemo
                }
                alt="ProfileImage"
              />
            </div>
            <div className="row">
              <div className="col-5 text-right key">Name</div>
              <div className="col-7 text-left value">{companyDetails.name}</div>
            </div>
            <div className="row my-1">
              <div className="col-5 text-right key">Email</div>
              <div className="col-7 text-left value">
                {companyDetails.email}
              </div>
            </div>
            <div className="row">
              <div className="col-5 text-right key">Address</div>
              <div className="col-7 text-left value">
                {companyDetails.address}
              </div>
            </div>
            <div className="row my-1">
              <div className="col-5 text-right key">Users</div>
              <div className="col-7 text-left value">{companyDetails.User}</div>
            </div>
            <div className="row my-1">
              <div className="col-5 text-right key">Sub Admins</div>
              <div className="col-7 text-left value">
                {companyDetails.SubAdminCompany}
              </div>
            </div>
            <div className="row my-1">
              <div className="col-5 text-right key">Company Link</div>
              <div className="col-7 text-left value">{companyDetails.link}</div>
            </div>
            <div className="row my-1">
              <div className="col-5 text-right key">Company Type</div>
              <div className="col-7 text-left value">
                {companyDetails.isComplex ? "True" : "False"}
              </div>
            </div>
            <div className="row my-1">
              <div className="col-5 text-right key">Company Level</div>
              <div className="col-7 text-left value">
                {companyDetails.company_level}
              </div>
            </div>

            <div className="row">
              <div className="col-5 text-right key">Description</div>
              <div className="col-7 text-left value">
                {companyDetails.description}
              </div>
            </div>
          </Wrapper>
        </ModalBody>
      </Modal>
    </Wrapper>
  );
};

export default viewCompanyModal;
