import { withFormik } from "formik";
import * as Yup from "yup";


const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    userName: Yup.string().required("This field is required"),
    email: Yup.string()
      .required("This Field is required")
      .email("Please Enter Valid Email"),
  }),

  // isInitialValid: true,
  mapPropsToValues: props => ({
    userName: props.editedData ? props.editedData.userName : "",
    email: props.editedData ? props.editedData.email : "",
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
