import React from "react";
import CompanyWrapper from "../UserModule/user.style";
import PageTitle from "components/common/PageTitle";
import CompanyTable from "../../components/companyModule/companyTable";
import { connect } from "react-redux";

const Company = (props) => {
  const isEdit = localStorage.getItem("isEdit");
  const isSuper = localStorage.getItem("isSuper");

  return (
    <CompanyWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          title="companies"
          className="plr-15"
          iconClass="fas fa-building"
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/dashboard")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="user-tbl">
        <CompanyTable {...props} />
      </div>
    </CompanyWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
  };
};

export default connect(mapStateToProps, null)(Company);
