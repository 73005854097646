import auth from "./auth/reducer";
import themeChanger from "./themeChanger/reducer";
// import LanguageSwitcher from "./languageSwitcher/reducer";
import themeSetting from "./themeSettings/reducer";

export default {
  auth,
  themeChanger,
  // LanguageSwitcher,
  themeSetting
};
