import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { loginBack, ForgotIcon } from "helper/constant";
import { forgotPassword } from "../../../api/index";
import CustomToast from "../../../components/customToast/CustomToast";
import enhancer from "./enhancer/forgotPassEnhancer";
import { compose } from "redux";

const ForgotPassword = props => {
  const [toast, setCustomToast] = useState(false);
  const [toastClass, setToastClass] = useState("");
  const [toastMessage, setToastMessage] = useState("");

  const loginContainer = {
    // backgroundImage: `url(${loginBack})`,
    background: "linear-gradient(45deg,#46b3c3,#0E8EA4,#115E67)",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };
  const handleSubmit = e => {
    e.preventDefault();
    props.handleSubmit();
    if (props.isValid) {
      const data = {
        email: props.values.email
      };
      forgotPassword(data).then(res => {
        if (res && res.status) {
          setToastClass("c-success");
          setToastMessage(res.message);
          setCustomToast(true);
        } else {
          if (res) {
            setToastClass("c-danger");
            setToastMessage(res.message);
            setCustomToast(true);
          }
        }
      });
    }
  };
  const { errors, touched, submitCount } = props;

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      {toast && (
        <CustomToast
          heading={"Alert!"}
          width={400}
          show="bottomRight"
          transition
          position="bottom-right"
          className={toastClass}
          message={toastMessage}
          onCloseCLick={() => setCustomToast(false)}
        />
      )}
      <div className="form-container">
        <div className="login-icon">
          <img src={ForgotIcon} alt="icon" height="100px" />
        </div>
        <div className="login-title">Forgot Password ?</div>
        <div className="text-center form-info-text plr-24 mt-16">
          Provide your e-mail address to reset your password
        </div>
        <form className="pa-24" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              className="form-control react-form-input"
              id="email"
              onChange={props.handleChange}
              value={props.values.email}
              onBlur={props.handleBlur}
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
            <Error field="email" />
          </div>
          <button type="submit" className="btn form-button">
            Get Password Reset Link
          </button>
          <div
            className="text-center link-label"
            onClick={() => props.history.push("/login")}>
            Login
          </div>
        </form>
      </div>
    </div>
  );
};

export default compose(enhancer)(withRouter(ForgotPassword));
