import React, { useState, useEffect } from "react";
import DashboardWrapper from "../dashboard.style";
import { Container, Row, Col } from "reactstrap";
import Card from "../Card/Card";
import { viewStatistics, viewStatisticsCompanySAC } from "../../../api/index";

const DashboardCards = () => {
  const [userscounts, setusersCounts] = useState({
    user: 0
  });
  const [subadminscounts, setsubadminscounts] = useState({
    subadmin: 0
  });

  useEffect(() => {
    viewStatistics().then(res => {
      if (res && res.status) {
        const usersdata = {
          ...userscounts,
          user: res.data.list.users,
        };
        setusersCounts(usersdata);
      } else {
        setusersCounts(userscounts);
      }
    });

    viewStatisticsCompanySAC().then(res => {
      if (res && res.status) {
        const subminsdata = {
          ...subadminscounts,
          subadmin: res.data.list.subadmins,
        };
        setsubadminscounts(subminsdata);
      } else {
        setsubadminscounts(subadminscounts);
      }
    });
  }, []);

  return (
    <DashboardWrapper>
      <Container fluid>
        <Row>
          
          <Col xs={12} lg={4} md={4} sm={4}>
            <Card
              title="Sub Admins"
              count={subadminscounts.subadmin}
              icon="fas fa-user-alt"
              bgColor="#0E8EA4"
              to="/subadmincompanies"></Card>
          </Col>
          <Col xs={12} lg={4} md={4} sm={4}>
            <Card
              title="Users"
              count={userscounts.user}
              icon="fas fa-user-alt"
              bgColor="#0E8EA4"
              to="/users"></Card>
          </Col>
        </Row>
      </Container>
    </DashboardWrapper>
  );
};

export default DashboardCards;
