import React, { useState, useEffect } from "react";
import DashboardWrapper from "../dashboard.style";
import { Container, Row, Col } from "reactstrap";
import Card from "../Card/Card";
import Custom_Card from "../Card/Custom_Card";

import { viewStatistics } from "../../../api/index";
import { viewStatisticsAllUsers } from "../../../api/index";
import { viewStatisticsAllSubAdmins } from "../../../api/index";

const DashboardCards = () => {
  const [counts, setCounts] = useState({
    companies: 0,
  });
  const [userscounts, setuserCounts] = useState({
    users: 0,
  });
  const [subadmin, setsubadminCounts] = useState({
    sub_admins: 0,
  });

  useEffect(() => {
    viewStatistics(true).then((res) => {
      if (res && res.status) {
        const data = {
          ...counts,
          companies: res.data.list.companies,
        };
        setCounts(data);
      } else {
        setCounts(counts);
      }
    });
    viewStatisticsAllUsers(true).then((res) => {
      if (res && res.status) {
        const usersdata = {
          ...userscounts,
          users: res.data.list.users,
        };
        setuserCounts(usersdata);
      } else {
        setuserCounts(userscounts);
      }
    });
    viewStatisticsAllSubAdmins(true).then((res) => {
      if (res && res.status) {
        const subadmindata = {
          ...subadmin,
          sub_admins: res.data.list.subadmins,
        };
        setsubadminCounts(subadmindata);
      } else {
        setsubadminCounts(subadmin);
      }
    });
  }, []);

  return (
    <DashboardWrapper>
      <Container fluid>
        <Row>
          <Col xs={12} lg={4} md={4} sm={4}>
            <Card
              title="Companies"
              count={counts.companies}
              icon="fas fa-building"
              bgColor="#0E8EA4"
              to="/companies"
            ></Card>
          </Col>
          <Col xs={12} lg={4} md={4} sm={4}>
            <Card
              title="All Users"
              count={userscounts.users}
              icon="fas fa-building"
              bgColor="#0E8EA4"
              to="/allusersfromadmindashboard"
            ></Card>
          </Col>
          <Col xs={12} lg={4} md={4} sm={4}>
            <Card
              title="All SubAdmin"
              count={subadmin.sub_admins}
              icon="fas fa-building"
              bgColor="#0E8EA4"
              to="/allsacfromadmindashboard"
            ></Card>
          </Col>
        </Row>
      </Container>
    </DashboardWrapper>
  );
};

export default DashboardCards;
