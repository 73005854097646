import React, { useState, useEffect, Fragment } from "react";
import ReactTable from "react-table-6";
import Button from "components/button/Button";
import RoyTooltip from "components/common/RoyTooltip";
import Pagination from "components/common/Pagination";
import "react-table-6/react-table.css";
import swal from "sweetalert";
import { withRouter } from "react-router";
import {
  viewAllUsersFromAdmin,
  activateDeactivateUser,
  deleteUserFromAdmin,
} from "../../api/index";
import { getPagination } from "../../helper/constant";
import ViewUserModal from "./viewUserModal";

let debounceTimer;

const UserFromAdminTable = (props) => {
  const isEdit = localStorage.getItem("isEdit");
  const isSuper = localStorage.getItem("isSuper");

  const hasCompany_ID = props.match.params.hasOwnProperty("company_id");
  let company_id_number;
  if (hasCompany_ID) {
    company_id_number = props.match.params.company_id;
  } else {
    company_id_number = undefined;
  }

  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pages, setPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [params, setParams] = useState({
    orderBy: "createdAt",
    orderSequence: "DESC",
    pageNo: 0,
    pageSize: 8,
    search: "",
  });

  const [userDetails, setuserDetails] = useState({});
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const {
    sidebarTheme: { activeColor, textColor },
  } = props;

  useEffect(() => {
    if (searchText !== null) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
      debounceTimer = setTimeout(callSearchApi, 500);
    }
  }, [searchText]);

  useEffect(() => {
    viewAllUsersFromAdmin(params, company_id_number).then(async (res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = await getPagination(
          res.data.totalRecords,
          params.pageSize
        );
        setPages(pages);
      }
    });
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
    };
  }, []);

  //Add User and Edit User Handler
  const formAction = (action, data = null) => {
    if (action === "add") {
      props.history.push(`/addusersfromadmin/add/${company_id_number}`);
    } else if (action === "edit") {
      props.history.push(
        `/editusersfromadmin/edit/${data.id}/${company_id_number}`,
        {
          ...data,
        }
      );
    }
  };

  //Api for viewAllUsersFromAdmin
  const getUserList = () => {
    viewAllUsersFromAdmin(params, company_id_number).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
      }
    });
  };

  //Page Change Handler
  const onPageChangeHandler = (index) => {
    let page = index;
    setActivePage(page + 1);
    let data = {
      ...params,
      pageNo: page,
    };
    setParams(data);
    viewAllUsersFromAdmin(data, company_id_number).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
      }
    });
  };

  const showOneUser = (data) => {
    setuserDetails(data);
    setModal(true);
  };

  //Api for the search in UserModule
  const callSearchApi = () => {
    const data = {
      ...params,
      search: searchText,
    };
    setParams(data);
    viewAllUsersFromAdmin(data, company_id_number).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
      }
    });
  };

  //Status Handler
  const activeInactiveStatusHandler = (id) => {
    activateDeactivateUser(id).then((res) => {
      getUserList();
    });
  };

  //Delete Page
  const deletePage = (pageId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteUserFromAdmin(pageId, company_id_number).then((res) => {
          if (res && res.status) {
            swal(res.message, {
              icon: "success",
            });
            viewAllUsersFromAdmin(params, company_id_number).then((res) => {
              if (res && res.status) {
                settblData(res.data.list);
                setLoader(false);
                const pages = getPagination(
                  res.data.totalRecords,
                  params.pageSize
                );
                setPages(pages);
              }
              if (res && !res.data.list.length) {
                onPageChangeHandler(params.pageNo - 1);
              }
            });
            getUserList();
          } else {
            if (res) {
              swal(res.message, {
                icon: "danger",
              });
            }
          }
        });
      }
    });
  };

  //Sort Handler
  const onSortChange = (sortProperties) => {
    const orderByDirection = sortProperties[0].desc ? 1 : 0;
    if (!orderByDirection) {
      let data = {
        ...params,
        orderBy: sortProperties[0].id,
        orderSequence: "ASC",
      };
      setParams(data);
      viewAllUsersFromAdmin(data, company_id_number).then((res) => {
        if (res && res.status) {
          settblData(res.data.list);
          setLoader(false);
        }
      });
    } else {
      let data = {
        ...params,
        orderSequence: "DESC",
      };
      setParams(data);
      viewAllUsersFromAdmin(data, company_id_number).then((res) => {
        if (res && res.status) {
          settblData(res.data.list);
          setLoader(false);
        }
      });
    }
  };

  //Columns of the Table
  const columns = React.useMemo(
    () => [
      {
        Header: "User name",
        accessor: "userName",
        className: "text-center",
        filterable: false,
        headerClassName: "react-table-header-class -sort",
      },
      {
        Header: "Email",
        accessor: "email",
        className: "text-center",
        filterable: false,
        sortable: false,
        headerClassName: "react-table-header-class",
      },
      {
        Header: "User Created",
        accessor: "createdAt",
        className: "text-center",
        filterable: false,
        sortable: true,
        headerClassName: "react-table-header-class -sort",
        Cell: (props) => {
          return `${new Date(props.original.createdAt).getMonth() +
            1}/${new Date(props.original.createdAt).getDate()}/${new Date(
            props.original.createdAt
          ).getFullYear()}`;
        },
        width: 180,
      },

      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        headerClassName: "react-table-header-class",
        sortable: false,
        filterable: false,
        width: 300,
        Cell: (props) => (
          <div className="react-action-class">
            <Button
              className="c-btn c-info mr-10"
              onClick={() => showOneUser(props.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-eye mr-6" /> View
              </div>
            </Button>

            {isEdit === "true" && isSuper === "true" ? (
              <Button
                className="c-btn c-success mr-10"
                onClick={() => formAction("edit", props.original)}
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-edit mr-6" /> Edit
                </div>
              </Button>
            ) : (
              ""
            )}

            {isEdit === "true" && isSuper === "true" ? (
              <Button
                className="c-btn c-danger"
                onClick={() => deletePage(props.original.id)}
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-trash mr-6" /> Delete
                </div>
              </Button>
            ) : (
              ""
            )}
          </div>
        ), // Custom cell components!
      },
    ],
    [params]
  );

  return (
    <React.Fragment>
      <ViewUserModal userDetails={userDetails} toggle={toggle} modal={modal} />
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              {/* <span className="hash"># </span> All Users */}
            </div>
            <div className="mr-10">
              <input
                value={searchText || ""}
                onChange={(e) => setSearchText(e.target.value)}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>

            {isEdit === "true" && isSuper === "true" ? (
              <div>
                <Button
                  className="c-btn ma-5"
                  onClick={() => formAction("add")}
                  style={{
                    backgroundColor: activeColor,
                    color: textColor,
                  }}
                >
                  <i className="fas fa-plus mr-10"></i>Add User
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={2}
              minRows={0}
              pages={pages}
              filterable={false}
              manual
              className="-striped -highlight custom-react-table-theme-class"
              page={activePage - 1}
              PaginationComponent={Pagination}
              loading={loader}
              onPageChange={(pageIndex) => onPageChangeHandler(pageIndex)}
              onSortedChange={(sortProperties) => onSortChange(sortProperties)}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserFromAdminTable);
