import React, { useState, useEffect } from "react";
import ReactTable from "react-table-6";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table-6/react-table.css";
import swal from "sweetalert";
import { withRouter } from "react-router";
import { viewAllCompanies, deleteCompany } from "../../api/index";
import { getPagination } from "../../helper/constant";
import { iconDemo } from "helper/constant";
import ViewCompanyModal from "./viewCompanyModal";

let debounceTimer;
const AWS_URL = process.env.REACT_APP_AWS_URL;

const CompanyTable = (props) => {
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pages, setPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [params, setParams] = useState({
    orderBy: "createdAt",
    orderSequence: "DESC",
    pageNo: 0,
    pageSize: 8,
    search: "",
  });

  const [companyDetails, setcompanyDetails] = useState({});
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const isEdit = localStorage.getItem("isEdit");
  const isSuper = localStorage.getItem("isSuper");

  const {
    sidebarTheme: { activeColor, textColor },
  } = props;

  useEffect(() => {
    if (searchText !== null) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
      debounceTimer = setTimeout(callSearchApi, 500);
    }
  }, [searchText]);

  useEffect(() => {
    viewAllCompanies(params).then(async (res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = await getPagination(
          res.data.totalRecords,
          params.pageSize
        );
        setPages(pages);
      }
    });
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
    };
  }, []);

  const formAction = (action, data = null) => {
    if (action === "add") {
      props.history.push("/companies/add");
    } else if (action === "edit") {
      props.history.push(`/companies/edit/${data.id}`, {
        ...data,
      });
    } else if (action === "subadmin") {
      props.history.push(`/subadmincompaniesfromadmin/${data.id}`);
    } else if (action === "viewusers") {
      props.history.push(`/viewusersfromadmin/${data.id}`);
    }
  };

  const getUserList = () => {
    viewAllCompanies(params).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
      }
    });
  };

  const onPageChangeHandler = (index) => {
    let page = index;
    setActivePage(page + 1);
    let data = {
      ...params,
      pageNo: page,
    };
    setParams(data);
    viewAllCompanies(data).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
      }
    });
  };

  const callSearchApi = () => {
    const data = {
      ...params,
      search: searchText,
    };
    setParams(data);
    viewAllCompanies(data).then((res) => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
      }
    });
  };

  const deleteOneCompany = (companyId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteCompany(companyId).then((res) => {
          if (res && res.status) {
            swal(res.message, {
              icon: "success",
            });
            viewAllCompanies(params).then((res) => {
              if (res && res.status) {
                settblData(res.data.list);
                setLoader(false);
                const pages = getPagination(
                  res.data.totalRecords,
                  params.pageSize
                );
                setPages(pages);
              }
              if (res && !res.data.list.length) {
                onPageChangeHandler(params.pageNo - 1);
              }
            });
            getUserList();
          } else {
            if (res) {
              swal(res.message, {
                icon: "danger",
              });
            }
          }
        });
      }
    });
  };

  const onSortChange = (sortProperties) => {
    const orderByDirection = sortProperties[0].desc ? 1 : 0;
    if (!orderByDirection) {
      let data = {
        ...params,
        orderBy: sortProperties[0].id,
        orderSequence: "ASC",
      };
      setParams(data);
      viewAllCompanies(data).then((res) => {
        if (res && res.status) {
          settblData(res.data.list);
          setLoader(false);
        }
      });
    } else {
      let data = {
        ...params,
        orderSequence: "DESC",
      };
      setParams(data);
      viewAllCompanies(data).then((res) => {
        if (res && res.status) {
          settblData(res.data.list);
          setLoader(false);
        }
      });
    }
  };

  const showOneUser = (data) => {
    setcompanyDetails(data);
    setModal(true);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Logo",
        accessor: "logo",
        className: "text-center",
        headerClassName: "react-table-header-class ",
        filterable: false,
        sortable: false,
        width: 80,
        Cell: (props) => (
          <div className="react-action-class">
            <img
              height="30px"
              src={
                props.original.logo
                  ? `${AWS_URL}${props.original.logo}`
                  : iconDemo
              }
              alt="profile"
            />
          </div>
        ), // Custom cell components!
      },
      {
        Header: "Name",
        accessor: "name",
        className: "text-left",
        filterable: false,
        headerClassName: "react-table-header-class -sort",
        width: 300,
      },
      {
        Header: "Email",
        accessor: "email",
        className: "text-left",
        filterable: false,
        sortable: false,
        headerClassName: "react-table-header-class",
        width: 300,
      },
      {
        Header: "Company Created",
        accessor: "createdAt",
        className: "text-center",
        filterable: false,
        headerClassName: "react-table-header-class -sort",
        Cell: (props) => {
          return `${new Date(props.original.createdAt).getMonth() +
            1}/${new Date(props.original.createdAt).getDate()}/${new Date(
            props.original.createdAt
          ).getFullYear()}`;
        },
        width: 150,
      },
      {
        Header: "Users",
        accessor: "User",
        className: "text-center",
        filterable: false,
        headerClassName: "react-table-header-class",
        width: 70,
      },
      {
        Header: "SubAdmins",
        accessor: "SubAdminCompany",
        className: "text-center",
        filterable: false,
        headerClassName: "react-table-header-class",
        width: 50,
      },
      {
        id: "isComplex",
        Header: "isComplex",
        accessor: (d) => {
          return d.isComplex ? "True" : "False";
        },
        className: "text-center",
        filterable: false,
        headerClassName: "react-table-header-class",
        width: 50,
      },
      {
        Header: "company_level",
        accessor: "company_level",
        className: "text-center",
        filterable: false,
        headerClassName: "react-table-header-class",
        width: 50,
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        headerClassName: "react-table-header-class",
        sortable: false,
        filterable: false,
        width: 525,
        Cell: (props) => (
          <div className="react-action-class">
            <Button
              className="c-btn c-secondary mr-10"
              onClick={() => formAction("viewusers", props.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-users mr-6" /> Users
              </div>
            </Button>
            <Button
              className="c-btn c-warning mr-10"
              onClick={() => formAction("subadmin", props.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-user mr-6" /> SubAdmin
              </div>
            </Button>
            <Button
              className="c-btn c-info mr-10"
              onClick={() => showOneUser(props.original)}
            >
              <div className="fs-14 medium-text">
                <i className="fas fa-eye mr-6" /> View
              </div>
            </Button>
            {isEdit === "true" && isSuper === "true" ? (
              <Button
                className="c-btn c-success mr-10"
                onClick={() => formAction("edit", props.original)}
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-edit mr-6" /> Edit
                </div>
              </Button>
            ) : (
              ""
            )}
            {isEdit === "true" && isSuper === "true" ? (
              <Button
                className="c-btn c-danger"
                onClick={() => deleteOneCompany(props.original.id)}
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-trash mr-6" /> Delete
                </div>
              </Button>
            ) : (
              ""
            )}
          </div>
        ), // Custom cell components!
      },
    ],
    [params]
  );

  return (
    <React.Fragment>
      <ViewCompanyModal
        companyDetails={companyDetails}
        toggle={toggle}
        modal={modal}
      />
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              {/* <span className="hash"># </span> Companies */}
            </div>
            <div className="mr-10">
              <input
                value={searchText || ""}
                onChange={(e) => setSearchText(e.target.value)}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            {isEdit === "true" && isSuper === "true" ? (
              <div>
                <Button
                  className="c-btn ma-5"
                  onClick={() => formAction("add")}
                  style={{
                    backgroundColor: activeColor,
                    color: textColor,
                  }}
                >
                  <i className="fas fa-plus mr-10"></i>Add Company
                </Button>
              </div>
            ) : (
              ""
            )}
            {/* {
              if(isEdit && isSuper)
              {

              }
            } */}
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={2}
              minRows={0}
              pages={pages}
              filterable={false}
              manual
              className="-striped -highlight custom-react-table-theme-class"
              page={activePage - 1}
              PaginationComponent={Pagination}
              loading={loader}
              onPageChange={(pageIndex) => onPageChangeHandler(pageIndex)}
              onSortedChange={(sortProperties) => onSortChange(sortProperties)}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CompanyTable);
