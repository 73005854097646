import axios from "axios";
import { logout } from "../redux/auth/actions";
import { store } from "../redux/store";
//LOCAL
// const BASEURL = process.env.REACT_APP_LOCAL_URL;
//SERVER
const BASEURL = process.env.REACT_APP_STAGING_URL;

const setAccessToken = () =>
  (axios.defaults.headers.common[
    "Authorization"
  ] = store.getState().auth.accessToken);

const handleError = (err) => {
  if (err.response && err.response.status === 401) {
    store.dispatch(logout());
  } else {
    console.log(err);
  }
};

//CMS-AUTH
export const signOut = () => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/auth/logout/${store.getState().auth.isSuper}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const signIn = (data) => {
  return axios
    .post(`${BASEURL}/auth/login`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const forgotPassword = (data) => {
  return axios
    .post(`${BASEURL}/auth/forgotpassword`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const viewStatistics = () => {
  setAccessToken();
  return axios
    .get(
      `${BASEURL}/auth/viewstatistics/${store.getState().auth.isSuper}/${
        store.getState().auth.details.id
      }`
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const viewStatisticsAllUsers = () => {
  setAccessToken();
  return axios
    .get(
      `${BASEURL}/auth/viewstatisticsallusers/${
        store.getState().auth.isSuper
      }/${store.getState().auth.details.id}`
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const viewStatisticsAllSubAdmins = () => {
  setAccessToken();
  return axios
    .get(
      `${BASEURL}/auth/viewstatisticsallsubadmins/${
        store.getState().auth.isSuper
      }/${store.getState().auth.details.id}`
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const viewStatisticsCompanySAC = () => {
  setAccessToken();
  return axios
    .get(
      `${BASEURL}/auth/viewStatisticscompany/${
        store.getState().auth.details.id
      }`
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const viewStatisticsSAC = () => {
  console.log(store.getState().auth.details.id);
  setAccessToken();
  return axios
    .get(
      `${BASEURL}/auth/viewStatisticssubadmincompany/${
        store.getState().auth.details.id
      }`
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const viewSAC_ID = () => {
  setAccessToken();
  return axios
    .get(
      `${BASEURL}/auth/viewcompanyidfromsac/${store.getState().auth.details.id}`
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

//SUPER-ADMIN
export const resetPassword = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/super/changepassword`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

//SUPER-ADMIN > COMPANY

export const addCompany = (data) => {
  if (data.isComplex === "") {
    data.isComplex = false;
  }
  setAccessToken();
  return axios
    .post(`${BASEURL}/super/company/addcompany`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const viewAllCompanies = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/super/company/viewallcompanies`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

// export const viewAllSubAdminCompanies = (data) => {
//   setAccessToken();
//   return axios
//     .post(`${BASEURL}/super/subadmincompany/viewallsubadmincompanies`, data)
//     .then((res) => res.data)
//     .catch((err) => handleError(err));
// };

export const viewAllSubAdminCompanies = (data, company_id) => {
  let company_id_number;
  if (company_id === "undefined" || typeof company_id === "undefined") {
    company_id_number = store.getState().auth.details.id;
  } else {
    company_id_number = company_id;
  }
  // store.getState().auth.details.id
  setAccessToken();
  return axios
    .post(
      `${BASEURL}/company/subadmincompany/viewallsubadmincompanies/${company_id_number}`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const viewAllSACFromAdminDashboard = (data) => {
  setAccessToken();
  return axios
    .post(
      `${BASEURL}/company/subadmincompany/viewallsacfromadmindashboard/`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const viewAllSACFromSAC = (data) => {
  setAccessToken();
  return axios
    .post(
      `${BASEURL}/company/subadmincompany/viewallsacfromsac/${
        store.getState().auth.details.id
      }`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const editCompany = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/super/company/editcompany/${data.id}`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const deleteCompany = (companyId) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/super/company/deletecompany/${companyId}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

//COMPANY
export const getCompanyInfo = () => {
  setAccessToken();
  return axios
    .get(
      `${BASEURL}/company/getcompanyinfo/${store.getState().auth.details.id}`
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const getSubAdminCompanyInfo = () => {
  setAccessToken();
  return axios
    .get(
      `${BASEURL}/subadmincompany/getsubadmincompanyinfo/${
        store.getState().auth.details.id
      }`
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const editProfile = (data) => {
  setAccessToken();
  return axios
    .post(
      `${BASEURL}/company/editprofile/${store.getState().auth.details.id}`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const subadmincompanyeditProfile = (data) => {
  setAccessToken();
  return axios
    .post(
      `${BASEURL}/subadmincompany/editprofile/${
        store.getState().auth.details.id
      }`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const changePassword = (data) => {
  setAccessToken();
  return axios
    .post(
      `${BASEURL}/company/changepassword/${store.getState().auth.details.id}`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const subadminchangePassword = (data) => {
  setAccessToken();
  console.log(store.getState().auth.details.id);
  return axios
    .post(
      `${BASEURL}/subadmincompany/changepassword/${
        store.getState().auth.details.id
      }`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

//COMPANY > USER
export const viewAllUsers = (data) => {
  setAccessToken();
  return axios
    .post(
      `${BASEURL}/company/user/viewallusers/${
        store.getState().auth.details.id
      }`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

//ADMIN > ALLUSER
export const viewAllUsersFromAdminDashboard = (data) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/company/user/viewallusersfromadmindashboard/`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const viewAllUsersFromAdmin = (data, company_id) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/company/user/viewallusersfromadmin/${company_id}`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const viewAllUsersFromSAC = (data) => {
  setAccessToken();
  return axios
    .post(
      `${BASEURL}/company/user/viewallusersfromsac/${
        store.getState().auth.details.id
      }`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const addUser = (data) => {
  setAccessToken();
  return axios
    .post(
      `${BASEURL}/company/user/adduser/${store.getState().auth.details.id}`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const addUserfromAdmin = (data, company_id) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/company/user/adduser/${company_id}`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const addUserFromSAC = (data) => {
  setAccessToken();
  return axios
    .post(
      `${BASEURL}/company/user/adduserfromsac/${
        store.getState().auth.details.id
      }`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const addSubAdminCompany = (data, company_id) => {
  setAccessToken();
  let company_id_number;
  if (company_id === "undefined" || typeof company_id === "undefined") {
    company_id_number = store.getState().auth.details.id;
  } else {
    company_id_number = company_id;
  }

  return axios
    .post(
      `${BASEURL}/company/subadmincompany/addsubadmincompany/${company_id_number}`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const addSubAdminCompanyFromSAC = (data) => {
  setAccessToken();
  return axios
    .post(
      `${BASEURL}/company/subadmincompany/addsubadmincompanyfromsac/${
        store.getState().auth.details.id
      }`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const editUser = (data) => {
  setAccessToken();
  return axios
    .post(
      `${BASEURL}/company/user/edituser/${store.getState().auth.details.id}`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const editUserFromAdmin = (data, company_id) => {
  setAccessToken();
  return axios
    .post(`${BASEURL}/company/user/edituserfromadmin/${company_id}`, data)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const editUserFromSAC = (data) => {
  setAccessToken();
  return axios
    .post(
      `${BASEURL}/company/user/edituserfromsac/${
        store.getState().auth.details.id
      }`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const editSubAdminCompany = (data, company_id) => {
  let company_id_number;
  console.log(data);
  if (company_id === "undefined" || typeof company_id === "undefined") {
    company_id_number = store.getState().auth.details.id;
  } else {
    company_id_number = company_id;
  }
  setAccessToken();
  return axios
    .post(
      `${BASEURL}/company/subadmincompany/editsubadmincompany/${company_id_number}`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const editSubAdminCompanyFormSAC = (data) => {
  setAccessToken();
  return axios
    .post(
      `${BASEURL}/company/subadmincompany/editsubadmincompanyfromsac/${
        store.getState().auth.details.id
      }`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const deleteUser = (userId) => {
  setAccessToken();
  return axios
    .get(
      `${BASEURL}/company/user/deleteuser/${userId}/${
        store.getState().auth.details.id
      }`
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const deleteUserFromAdmin = (userId, company_id) => {
  setAccessToken();
  return axios
    .get(`${BASEURL}/company/user/deleteuserfromadmin/${userId}/${company_id}`)
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const deleteUserFromSAC = (userId) => {
  setAccessToken();
  return axios
    .get(
      `${BASEURL}/company/user/deleteuserfromsac/${userId}/${
        store.getState().auth.details.id
      }`
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const deleteSubAdminCompany = (subadmincompanyId, company_id) => {
  let company_id_number;
  if (company_id === "undefined" || typeof company_id === "undefined") {
    company_id_number = store.getState().auth.details.id;
  } else {
    company_id_number = company_id;
  }
  // store.getState().auth.details.id
  setAccessToken();
  return axios
    .get(
      `${BASEURL}/company/subadmincompany/deletesubadmincompany/${subadmincompanyId}/${company_id_number}`
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const deleteSACFromSAC = (subadmincompanyId) => {
  setAccessToken();
  return axios
    .get(
      `${BASEURL}/company/subadmincompany/deletesacfromsac/${
        store.getState().auth.details.id
      }/${subadmincompanyId}`
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const activateDeactivateUser = (userId) => {
  setAccessToken();
  return axios
    .get(
      `${BASEURL}/company/user/activatedeactivateuser/${userId}/${
        store.getState().auth.details.id
      }`
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const activateDeactivateUserFromSAC = (userId) => {
  setAccessToken();
  return axios
    .get(
      `${BASEURL}/company/user/activatedeactivateuserfromsac/${userId}/${
        store.getState().auth.details.id
      }`
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

// export const viewOneUser = userId => {
//   setAccessToken();
//   return axios
//     .get(`${BASEURL}/user/viewoneuser/${userId}`)
//     .then(res => res.data)
//     .catch(err => handleError(err));
// };

//COMPANY > REPORTS

export const getUsers = (data) => {
  setAccessToken();
  return axios
    .post(
      `${BASEURL}/company/report/getusers/${store.getState().auth.details.id}`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const getReadings = (data) => {
  setAccessToken();
  return axios
    .post(
      `${BASEURL}/company/report/getreadings/${
        store.getState().auth.details.id
      }`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};

export const getReadingsForExport = (data) => {
  setAccessToken();
  return axios
    .post(
      `${BASEURL}/company/report/getreadingsforexport/${
        store.getState().auth.details.id
      }`,
      data
    )
    .then((res) => res.data)
    .catch((err) => handleError(err));
};
