import React, { useState, useEffect } from "react";
import ReactTable from "react-table-6";
import Pagination from "components/common/Pagination";
import "react-table-6/react-table.css";
import { withRouter } from "react-router";
import { viewAllUsers, getReadings, getReadingsForExport, getUsers } from "../../api/index";
import { getPagination } from "../../helper/constant";
import Select from "react-select";
import { ExportToCsv } from "export-to-csv";

let debounceTimer;

const convertDate = (date) => {
  let date_split = date.split("-");
  let date_type = new Date(
    parseInt(date_split[0]),
    parseInt(date_split[1]) - 1,
    parseInt(date_split[2])
  );
  const { format } = require("date-fns");
  const formattedDate = format(date_type, "dd-MMM-yyyy");
  return formattedDate;
};

const ReportTable = (props) => {
  const [tblData, settblData] = useState([]);
  const [tblDataForExport, settblDataForExport] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [params, setParams] = useState({
    orderBy: "createdAt",
    orderSequence: "DESC",
    pageNo: 0,
    pageSize: 15,
    user_id: 0,
    mode_of_operation: "Industrial",
  });
  const [users, setUsers] = useState([]);
  // const [DataSelection, setDataSelection] = useState("Daily")

  const options = {
    filename: "ExportedReprtTable",
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: "ExportedReprtTable",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,

    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };

  // if (tblData) {
  //   const csvExporter = new ExportToCsv(options);

  //   csvExporter.generateCsv(tblData);
  // }

  useEffect(() => {
    if (searchText !== null) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
      debounceTimer = setTimeout(callSearchApi, 500);
    }
  }, [searchText]);

  useEffect(() => {
    getUsers({ search: searchText ? searchText : "" }).then((res) => {
      if (res && res.status) {
        setUsers(res.data);
      }
    });
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
    };
  }, []);

  const onPageChangeHandler = (index) => {
    setLoader(true);
    let page = index;
    setActivePage(page + 1);
    let data = {
      ...params,
      pageNo: page,
    };
    setParams(data);
    getReadings(data).then(async (res) => {
      if (res && res.status) {
        setLoader(false);
        settblData(res.data.list);
        setLoader(false);
      }
    });
    getReadingsForExport(data).then(async (res) => {
      if (res && res.status) {
        setLoader(false);
        settblDataForExport(res.data.list);
        setLoader(false);
      }
    });
    
    

  };

  const callSearchApi = () => {
    const data = {
      search: searchText,
    };
    getUsers(data).then((res) => {
      if (res && res.status) {
        setUsers(res.data);
      }
    });
  };

  const onSortChange = (sortProperties) => {
    const orderByDirection = sortProperties[0].desc ? 1 : 0;
    if (!orderByDirection) {
      let data = {
        ...params,
        orderBy: sortProperties[0].id,
        orderSequence: "ASC",
      };
      setParams(data);
      getReadings(data).then(async (res) => {
        if (res && res.status) {
          setLoader(false);
          settblData(res.data.list);
          setLoader(false);
        }
      });
      getReadingsForExport(data).then(async (res) => {
        if (res && res.status) {
          setLoader(false);
          settblDataForExport(res.data.list);
          setLoader(false);
        }
      });
      viewAllUsers(data).then((res) => {
        if (res && res.status) {
          settblData(res.data.list);
          setLoader(false);
        }
      });
    } else {
      let data = {
        ...params,
        orderSequence: "DESC",
      };
      setParams(data);
      getReadings(data).then(async (res) => {
        if (res && res.status) {
          setLoader(false);
          settblData(res.data.list);
          setLoader(false);
        }
      });
      getReadingsForExport(data).then(async (res) => {
        if (res && res.status) {
          setLoader(false);
          settblDataForExport(res.data.list);
          setLoader(false);
        }
      });
      viewAllUsers(data).then((res) => {
        if (res && res.status) {
          settblData(res.data.list);
          settblDataForExport(res.data.list);
          setLoader(false);
        }
      });
    }
  };

  const modeOptions = [
    { value: "Industrial", label: "Industrial Mode" },
    { value: "Sampling", label: "Sampling Mode" },
  ];

  const dateRegex1 = /^\d{4}-\d{2}-\d{2}$/;

  const columns = [
    {
      Header: "Date",
      accessor: "date",
      className: "text-center",
      filterable: false,
      sortable: true,
      // Cell: (props) => (
      //   <div className="react-action-class">
      //     <span>{convertDate(props.original.date)}</span>
      //   </div>
      // ),
      Cell: (props) => (
        <div className="react-action-class">
          {dateRegex1.test(props.original.date) ? (
            <span>{convertDate(props.original.date)}</span>
          ) : (
            <span>{props.original.date}</span>
          )}
        </div>
      ), // Custom cell components!
      headerClassName: "react-table-header-class",
    },
    {
      Header: "Time",
      accessor: "time",
      className: "text-center",
      filterable: false,
      sortable: false,
      headerClassName: "react-table-header-class",
    },
    {
      Header: "Location",
      accessor: "location",
      className: "text-center",
      filterable: false,
      sortable: false,
      show: params.mode_of_operation === "Industrial" ? false : true,
      headerClassName: "react-table-header-class",
    },
    {
      Header: "PPM",
      accessor: "PPM",
      className: "text-center",
      filterable: false,
      sortable: false,
      headerClassName: "react-table-header-class",
      Cell: (props) => (
        <div className="react-action-class">
          {props.original.alarm_status === "" ? (
            <span>{props.original.PPM}</span>
          ) : (
            <div>
               {props.original.alarm_status === "Low Alarm" ? (
                <div
                  style={{ minWidth: "125px" }}
                  className="alarm_status c-btn c-warning mr-10"
                >
                  <div className="fs-14 medium-text">{props.original.PPM}</div>
                </div>
              ) : (
                <div
                  style={{ minWidth: "125px" }}
                  className="alarm_status high mr-10"
                >
                  <div className="fs-14 medium-text">{props.original.PPM}</div>
                </div>
              )}
            </div>
          )}
        </div>
      ),
    },
    // {
    //   Header: "Alarm Status",
    //   accessor: "alarm_status",
    //   className: "text-center",
    //   headerClassName: "react-table-header-class",
    //   filterable: false,
    //   sortable: false,
    //   Cell: (props) => (
    //     <div className="react-action-class">
    //       {props.original.alarm_status === "" ? (
    //         <span></span>
    //       ) : (
    //         <div>
    //           {props.original.alarm_status === "Low Alarm" ? (
    //             <div
    //               style={{ minWidth: "125px" }}
    //               className="alarm_status c-btn c-warning mr-10"
    //             >
    //               <div className="fs-14 medium-text">Low</div>
    //             </div>
    //           ) : (
    //             <div
    //               style={{ minWidth: "125px" }}
    //               className="alarm_status high mr-10"
    //             >
    //               <div className="fs-14 medium-text">High</div>
    //             </div>
    //           )}
    //         </div>
    //       )}
    //     </div>
    //   ), // Custom cell components!
    // },
    {
      Header: "Minute TWA",
      accessor: "min_TWA_value",
      className: "text-center",
      filterable: false,
      sortable: false,
      show: params.mode_of_operation === "Industrial" ? true : false,
      headerClassName: "react-table-header-class",
      Cell: ( props ) => {
        const inputString = props.original.sensor_meta_datum.min_TWA_threshold;
        const colonIndex = inputString.indexOf(':');
        let MinHighThreshold = 0 ;
        let MinLowThreshold = 0 ;
        if (colonIndex !== -1) {
          MinHighThreshold = inputString.slice(colonIndex + 1).trim();
          MinHighThreshold = parseFloat(MinHighThreshold, 10);
          MinLowThreshold = MinHighThreshold/2;
        }
         return (
         <div className="react-action-class">
          {props.original.min_TWA_value === 0 ? (
            <span>{props.original.min_TWA_value}</span>
          ) : (
            <div>
              {props.original.min_TWA_value > MinHighThreshold ? (
                <div
                style={{ minWidth: "125px" }}
                className="alarm_status high mr-10"
              >
                <div className="fs-14 medium-text">{props.original.min_TWA_value}</div>
              </div>
              ) : (
                <div>
              {props.original.min_TWA_value < MinHighThreshold && props.original.min_TWA_value > MinLowThreshold ? (
                <div
                style={{ minWidth: "125px" }}
                className="alarm_status c-btn c-warning mr-10"
              >
                <div className="fs-14 medium-text">{props.original.min_TWA_value}</div>
              </div>
              ) : (
                <span>{props.original.min_TWA_value}</span>
              )}
            </div>
              )}
            </div>
          )}
        </div>
         )
      }, 
    },
    {
      Header: "Hour TWA",
      accessor: "hour_TWA_value",
      className: "text-center",
      filterable: false,
      sortable: false,
      show: params.mode_of_operation === "Industrial" ? true : false,
      headerClassName: "react-table-header-class",
      Cell: ( props ) => {
        const inputString = props.original.sensor_meta_datum.hour_TWA_threshold;
        const colonIndex = inputString.indexOf(':');
        let HrHighThreshold = 0 ;
        let HrLowThreshold = 0 ;
        if (colonIndex !== -1) {
          HrHighThreshold = inputString.slice(colonIndex + 1).trim();
          HrHighThreshold = parseFloat(HrHighThreshold, 10);
          HrLowThreshold = HrHighThreshold/2;
        }

         return (
         <div className="react-action-class">
          {props.original.hour_TWA_value === 0 ? (
            <span>{props.original.hour_TWA_value}</span>
          ) : (
            <div>
              {props.original.hour_TWA_value > HrHighThreshold ? (
                <div
                style={{ minWidth: "125px" }}
                className="alarm_status high mr-10"
              >
                <div className="fs-14 medium-text">{props.original.hour_TWA_value}</div>
              </div>
              ) : (
                <div>
              {props.original.hour_TWA_value < HrHighThreshold && props.original.hour_TWA_value > HrLowThreshold ? (
                <div
                style={{ minWidth: "125px" }}
                className="alarm_status c-btn c-warning mr-10"
              >
                <div className="fs-14 medium-text">{props.original.hour_TWA_value}</div>
              </div>
              ) : (
                <span>{props.original.hour_TWA_value}</span>
              )}
            </div>
              )}
            </div>
          )}
        </div>
         )
      }, 
    },
    
    // {
    //   Header: "min_TWA_threshold",
    //   accessor: "sensor_meta_datum.serial_number",
    //   className: "text-center",
    //   filterable: false,
    //   sortable: false,
    //   show: params.mode_of_operation === "Industrial" ? true : false,
    //   headerClassName: "react-table-header-class",
    // },
    
    // {
    //   Header: "Action level TWA",
    //   accessor: "action_level_TWA_value",
    //   className: "text-center",
    //   filterable: false,
    //   sortable: false,
    //   show: params.mode_of_operation === "Industrial" ? true : false,
    //   headerClassName: "react-table-header-class",
    // },
    
  ];

  // const onDataSelectionHandler = (value) => {
  //   setDataSelection(value);
  //   if(value !== DataSelection){

  //   }
  // }

  const onUserSelectHandler = (values) => {
    setLoader(true);
    setParams({
      ...params,
      user_id: values.value,
    });
    const data = {
      ...params,
      user_id: values.value,
    };
    getReadings(data).then(async (res) => {
      if (res && res.status) {
        setLoader(false);
        settblData(res.data.list);
        setLoader(false);
        const pages = await getPagination(
          res.data.totalRecords,
          params.pageSize
        );
        setPages(pages);
      }
    });
    getReadingsForExport(data).then(async (res) => {
      if (res && res.status) {
        setLoader(false);
        settblDataForExport(res.data.list);
        setLoader(false);
        const pages = await getPagination(
          res.data.totalRecords,
          params.pageSize
        );
        setPages(pages);
      }
    });
  };

  const onModeSelectHandler = (values) => {
    setLoader(true);
    setParams({
      ...params,
      mode_of_operation: values.value,
    });
    const data = {
      ...params,
      mode_of_operation: values.value,
    };
    if (data.user_id !== 0) {
      getReadings(data).then(async (res) => {
        setLoader(false);
        if (res && res.status) {
          settblData(res.data.list);
          setLoader(false);
          const pages = await getPagination(
            res.data.totalRecords,
            params.pageSize
          );
          setPages(pages);
        }
      });
      getReadingsForExport(data).then(async (res) => {
        setLoader(false);
        if (res && res.status) {
          settblDataForExport(res.data.list);
          setLoader(false);
          const pages = await getPagination(
            res.data.totalRecords,
            params.pageSize
          );
          setPages(pages);
        }
      });
    } else {
      setLoader(false);
    }
  };
  return (
    <React.Fragment>
      <div
        className="Page-title mtb-15 plr-15"
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <button
          className="title-icon"
          style={{
            background: `${tblDataForExport.length === 0 ? "#777" : "#0e8ea4"}`,
            color: "#ffff",
            boxShadow: "20px",
            border: 0,
            height: "35px",
            width: "35px",
          }}
          disabled={tblDataForExport.length === 0 ? true : false}
          onClick={() => {
            if (tblDataForExport) {
              const csvExporter = new ExportToCsv(options);
              csvExporter.generateCsv(tblDataForExport);
            }
          }}
        >
          <i className="fas fa-download"></i>
        </button>
        <div
          className="title"
          style={{
            fontSize: 12,
            color: `${tblDataForExport.length === 0 ? "#777" : "#0e8ea4"}`,
          }}
        >
          Export
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="p-2">
              <div className="">
                <Select
                  cacheOptions
                  options={users}
                  placeholder="Select User"
                  filterOption={() => true}
                  onChange={onUserSelectHandler}
                  onInputChange={(e) => setSearchText(e)}
                  className="fs-14 medium-text select-user react-form-input"
                />
              </div>
            </div>
            <div className="p-2 ">
              <div className="">
                <Select
                  cacheOptions
                  options={modeOptions}
                  placeholder="Industrial Mode"
                  onChange={onModeSelectHandler}
                  className="fs-14 medium-text select-user react-form-input"
                />
              </div>
            </div>
            {/* <div className="ml-auto p-2">
            <div className="tabs">
                <div 
                  className={`indi-tab mr-2 ${DataSelection === "Daily" ? "selected" : "disable"}`}
                  onClick={(e) => onDataSelectionHandler("Daily")}
                >
                  Daily
                </div>
                <div 
                  className={`indi-tab mr-2 ${DataSelection === "Weekly" ? "selected" : "disable"}`}
                  onClick={(e) => onDataSelectionHandler("Weekly")}
                >
                  Weekly
                </div>
                <div 
                  className={`indi-tab mr-2 ${DataSelection === "Monthly" ? "selected" : "disable"}`}
                  onClick={(e) => onDataSelectionHandler("Monthly")}
                >
                  Monthly
                </div>
              </div>
            </div> */}
          </div>

          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={2}
              minRows={0}
              pages={pages}
              filterable={false}
              noDataText="No records found for this selection"
              manual
              className="-striped -highlight custom-react-table-theme-class"
              page={activePage - 1}
              PaginationComponent={Pagination}
              loading={loader}
              onPageChange={(pageIndex) => onPageChangeHandler(pageIndex)}
              onSortedChange={(sortProperties) => onSortChange(sortProperties)}
              sortable={true}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ReportTable);
