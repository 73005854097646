import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const PageTitle = ({
  title,
  sidebarTheme,
  className,
  breadCrumb,
  iconClass
}) => {
  const titleStyle = {
    background: sidebarTheme.activeColor,
    color: sidebarTheme.textColor,
    boxShadow: `0 5px 20px 0 rgba(0,0,0,.2), 0 13px 24px -11px ${sidebarTheme.activeColor}`
  };

  const titleColor = {
    color: sidebarTheme.activeColor
  };

  return (
    <Fragment>
      <div className={classNames("Page-title", "mtb-15", className)}>
        <div className="title-icon" style={titleStyle}>
          <i className={iconClass} />
        </div>
        <div style={titleColor} className="title">
          {title}
        </div>
      </div>
      {breadCrumb && (
        <div>
          <Breadcrumb className="custom-breadcumb">
            {breadCrumb &&
              breadCrumb.map((e, i) => {
                if (i === breadCrumb.length - 1) {
                  return (
                    <BreadcrumbItem key={i} active>
                      {e.name}
                    </BreadcrumbItem>
                  );
                } else {
                  return (
                    <BreadcrumbItem className="breadcumb-color" key={i}>
                      {e.name}
                    </BreadcrumbItem>
                  );
                }
              })}
          </Breadcrumb>
        </div>
      )}
    </Fragment>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(PageTitle);
