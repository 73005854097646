import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import styled from "styled-components";
import { iconDemo } from "../../helper/constant";

const Wrapper = styled.div`
  .key {
    font-family: montserrat-bold;
    font-size: 16px;
    overflow-wrap: break-word;
  }
  .value {
    overflow-wrap: break-word;
  }
`;

const IMGURL = process.env.REACT_APP_AWS_URL;

const viewUserModal = (props) => {
  const { modal, toggle, userDetails } = props;
  return (
    <Wrapper>
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <div>
            <h6>
              <strong>View User</strong>
            </h6>
          </div>
        </ModalHeader>
        <ModalBody>
          <Wrapper>
            <div className="text-center mb-15">
              <img
                className="img-thumbnail "
                style={{ width: "200px", height: "200px" }}
                src={
                  userDetails.logo ? `${IMGURL}${userDetails.logo}` : iconDemo
                }
                alt="ProfileImage"
              />
            </div>
            <div className="row">
              <div className="col-5 text-right key">UserName</div>
              <div className="col-7 text-left value">
                {userDetails.userName}
              </div>
            </div>
            <div className="row my-1">
              <div className="col-5 text-right key">Email</div>
              <div className="col-7 text-left value">{userDetails.email}</div>
            </div>
            <div className="row">
              <div className="col-5 text-right key">Status</div>
              <div className="col-7 text-left value">{userDetails.status}</div>
            </div>
            {/* <div className="row my-1">
              <div className="col-5 text-right key">Company</div>
              <div className="col-7 text-left value">
                {userDetails.company_id}
              </div>
            </div>
            <div className="row my-1">
              <div className="col-5 text-right key">Sub Company</div>
              <div className="col-7 text-left value">
                {userDetails.sub_admin_company_id}
              </div>
            </div> */}
          </Wrapper>
        </ModalBody>
      </Modal>
    </Wrapper>
  );
};

export default viewUserModal;
