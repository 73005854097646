import React, { useState, Fragment } from "react";
import enhancer from "./validator";
import CustomToast from "../customToast/CustomToast";
import ProfileImg from "../ProfileModal/profileImage/ProfileImage";
import Select from "react-select";

const AWS_URL = process.env.REACT_APP_AWS_URL;

const CompanyForm = (props) => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
  } = props;

  const [toast, showToast] = useState(false);
  const [logo, setlogo] = useState();

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const getImgObj = (imgObject) => {
    // console.log("IMAGEOBJECT", imgObject);
    // console.log(window.URL.createObjectURL(imgObject));

    var reader = new FileReader();
    reader.readAsDataURL(imgObject);
    reader.onload = function() {
      setlogo(reader.result);
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  };
  const handleSubmit = () => {
    props.handleSubmit();
    if (action === "edit") {
      const err = Object.getOwnPropertyNames(props.errors);
      if (props.isValid || !err.length) {
        let { values } = props;
        const submitData = {
          ...values,
          logo: logo ? logo : values.logo,
        };
        onSubmit(submitData, action);
      }
    }
    if (action === "add") {
      if (props.isValid) {
        let { values } = props;
        const subObj = {
          ...values,
          logo: logo ? logo : values.logo,
        };
        onSubmit(subObj, action);
      }
    }
  };

  return (
    <Fragment>
      {toast && (
        <CustomToast
          heading={"Alert!"}
          width={400}
          show="bottomRight"
          transition
          position="bottom-right"
          className="c-danger"
          message="You haven't changed Anything"
          onCloseCLick={() => showToast(false)}
        />
      )}
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Name <span className="c-text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            placeholder="Name"
          />
          <Error field="name" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Email <span className="c-text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            placeholder="Enter Email"
          />
          <Error field="email" />
        </div>
        {/* <div className="form-group">
          <label className="fs-16 medium-text">
            Base OR Complex <span className="c-text-danger"></span>
          </label>
          <input
            type="checkbox"
            className="form-control react-form-input"
            id="isComplex"
            onChange={handleChange}
            defaultChecked={values.isComplex}
          />

          <Error field="isComplex" />
        </div> */}
        <div className="form-group">
          <label className="fs-16 medium-text">
            isComplex <span className="c-text-danger">*</span>
          </label>
        </div>
        <div className="form-group">
          <label class="switch">
            <input
              type="checkbox"
              className="form-control react-form-input"
              id="isComplex"
              onChange={handleChange}
              defaultChecked={values.isComplex}
            />
            <span class="slider round"></span>
          </label>

          <Error field="isComplex" />
        </div>

        <div className="form-group">
          <label className="fs-16 medium-text">
            Address <span className="c-text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address}
            placeholder="Enter Address"
          />
          <Error field="address" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Description</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            placeholder="Enter Description"
          />
          <Error field="description" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Company Link</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="link"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.link}
            placeholder="Provide a Link"
          />
          <Error field="link" />
        </div>

        <div className="form-group">
          <label className="fs-16 medium-text">Logo</label>
          <ProfileImg
            className="text-left"
            getImgObj={getImgObj}
            url={values.logo ? `${AWS_URL}${values.logo}` : ""}
          />
        </div>
        <div>
          <button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default enhancer(CompanyForm);
