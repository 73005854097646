import React, { Component } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { StyleRoot } from "radium";
import layoutRoutes from "./routes/index.jsx";
import themes from "./settings/themes";
import { themeConfig } from "./settings";

import "./assets/scss/app.scss";
import "pretty-checkbox/src/pretty-checkbox.scss";

const hist = createBrowserHistory({
  basename: `${process.env.REACT_APP_BASENAME === 'true' ? "safecide-cms" : "" }`
});

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <StyleRoot>
          <Provider store={store}>
            {/*Start layout routes */}
            <Router history={hist}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => <Redirect to="/dashboard" />}
                />
                {layoutRoutes.map((prop, key) => {
                  return (
                    <Route
                      path={prop.path}
                      component={prop.component}
                      key={key}
                      history={hist}
                    />
                  );
                })}
              </Switch>
            </Router>
            {/*End layout routes */}
          </Provider>
        </StyleRoot>
      </ThemeProvider>
    );
  }
}

export default App;
