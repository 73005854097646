export const sidebarData = [
  {
    name: "SafeCide",
    iconClass: "fas fa-fire",
    access: "super",
    child: [
      {
        listname: "Dashboard",
        routepath: "/dashboard",
        shortname: "DB",
      },
      {
        listname: "Companies",
        routepath: "/companies",
        shortname: "CP",
      },
      {
        listname: "All Users",
        routepath: "/allusersfromadmindashboard",
        shortname: "AU",
      },
      {
        listname: "All Sub Admins",
        routepath: "/allsacfromadmindashboard",
        shortname: "AS",
      },
    ],
  },
  // {
  //   name: "SafeCide",
  //   iconClass: "fas fa-fire",
  //   access: "super",
  //   child: [
  //     {
  //       listname: "Dashboard",
  //       iconClass: "fas fa-home",
  //       child: [
  //         {
  //           listname: "SafeCide",
  //           routepath: "/dashboard",
  //           shortname: "SC",
  //         },
  //         {
  //           listname: "Companies",
  //           routepath: "/companies",
  //           shortname: "CP",
  //         },
  //         {
  //           listname: "All Users",
  //           routepath: "/allusersfromadmindashboard",
  //           shortname: "AU",
  //         },
  //         {
  //           listname: "All Sub Admins",
  //           routepath: "/allsacfromadmindashboard",
  //           shortname: "AS",
  //         },
  //       ],
  //     },
  //   ],
  // },

  {
    name: "Calibration",
    iconClass: "fas fa-cog",
    access: "super",
    child: [
      {
        listname: "BiasStation",
        routepath: "/biasstation",
        shortname: "BS",
      },
      {
        listname: "Bench Noise",
        routepath: "/benchnoise",
        shortname: "BN",
      },
    ],
  },

  //  {
  //    name: "SafeCide",
  //    iconClass: "fas fa-file",
  //    routepath: "/",
  //    access: "super",
  //  },
  //  {
  //    name: "    Dashboard",
  //    iconClass: "fas fa-tachometer-alt",
  //    routepath: "/dashboard",
  //    access: "super",
  //  },
  //  {
  //    name: "    companies",
  //    iconClass: "fas fa-building",
  //    routepath: "/companies",
  //    access: "super",
  //  },

  //  {
  //    name: "Calibration",
  //    iconClass: "fas fa-cog",
  //    routepath: "/",
  //    access: "super",
  //  },
  //  {
  //    name: "    Bias Station",
  //    iconClass: "fas fa-circle",
  //    routepath: "/bs",
  //    access: "super",
  //  },
  //  {
  //    name: "    Bench Noise",
  //    iconClass: "fas fa-circle",
  //    routepath: "/bn",
  //    access: "super",
  //  },
  {
    name: "Company Dashboard",
    iconClass: "fas fa-tachometer-alt",
    routepath: "/dashboard",
    access: "company",
  },
  {
    name: "users",
    iconClass: "fas fa-user",
    routepath: "/users",
    access: "company",
  },
  {
    name: "Sub Admins",
    iconClass: "fas fa-user-check",
    routepath: "/subadmincompanies",
    access: "company",
  },
  {
    name: "reports",
    iconClass: "fas fa-chart-bar",
    routepath: "/report",
    access: "company",
  },

  {
    name: "Sub Admin Dashboard",
    iconClass: "fas fa-tachometer-alt",
    routepath: "/dashboard",
    access: "subadmincompany",
  },
  {
    name: "Sub Admins",
    iconClass: "fas fa-user-check",
    routepath: "/sacfromsubadmincompanies",
    access: "subadmincompany",
  },
  {
    name: "users",
    iconClass: "fas fa-user",
    routepath: "/usersfromsubadmincompanies",
    access: "subadmincompany",
  },
  {
    name: "reports",
    iconClass: "fas fa-chart-bar",
    routepath: "/report",
    access: "subadmincompany",
  },
];

// Comments:::::::

//  If you want one level child then look below example

/*
  {
    name: 'sidebar.forms',
    iconClass: 'fab fa-wpforms',
    child: [
      {
        listname: 'sidebar.regularforms',
        routepath: '/regularform',
        shortname: 'RF'
      }
    ]
  }
*/

//  If you want Second level child then look below example

/*
   {
      name: 'sidebar.pages',
      iconClass: 'fas fa-print',
      child: [
        {
          listname: 'sidebar.authentication',
          iconClass: 'fas fa-user',
          child: [
            {
              listname: 'sidebar.login',
              routepath: '/login',
              shortname: 'L'
            },
          ]
        }
      ]
    }
*/
