import React, { useState, useEffect, Fragment } from "react";
import ReactTable from "react-table-6";
import Button from "components/button/Button";
import RoyTooltip from "components/common/RoyTooltip";
import Pagination from "components/common/Pagination";
import "react-table-6/react-table.css";
import swal from "sweetalert";
import { withRouter } from "react-router";
import {
  viewAllUsers,
  activateDeactivateUser,
  deleteUser
} from "../../api/index";
import { getPagination } from "../../helper/constant";

let debounceTimer;

const UserTable = props => {
  const [tblData, settblData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pages, setPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [params, setParams] = useState({
    orderBy: "createdAt",
    orderSequence: "DESC",
    pageNo: 0,
    pageSize: 8,
    search: ""
  });

  const {
    sidebarTheme: { activeColor, textColor }
  } = props;

  useEffect(() => {
    if (searchText !== null) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
      debounceTimer = setTimeout(callSearchApi, 500);
    }
  }, [searchText]);

  useEffect(() => {
    viewAllUsers(params).then(async res => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
        const pages = await getPagination(
          res.data.totalRecords,
          params.pageSize
        );
        setPages(pages);
      }
    });
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        debounceTimer = null;
      }
    };
  }, []);


  //Add User and Edit User Handler
  const formAction = (action, data = null) => {
    if (action === "add") {
      props.history.push("/users/add");
    } else if (action === "edit") {
      props.history.push(`/users/edit/${data.id}`, {
        ...data
      });
    }
  };

  //Api for ViewAllUsers
  const getUserList = () => {
    viewAllUsers(params).then(res => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
      }
    });
  };

  //Page Change Handler
  const onPageChangeHandler = index => {
    let page = index;
    setActivePage(page + 1);
    let data = {
      ...params,
      pageNo: page
    };
    setParams(data);
    viewAllUsers(data).then(res => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
      }
    });
  };

  //Api for the search in UserModule
  const callSearchApi = () => {
    const data = {
      ...params,
      search: searchText
    };
    setParams(data);
    viewAllUsers(data).then(res => {
      if (res && res.status) {
        settblData(res.data.list);
        setLoader(false);
      }
    });
  };

  //Status Handler
  const activeInactiveStatusHandler = id => {
    activateDeactivateUser(id).then(res => {
      getUserList();
    });
  };

  //Delete Page
  const deletePage = pageId => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        deleteUser(pageId).then(res => {
          if (res && res.status) {
            swal(res.message, {
              icon: "success"
            });
            viewAllUsers(params).then(res => {
              if (res && res.status) {
                settblData(res.data.list);
                setLoader(false);
                const pages = getPagination(
                  res.data.totalRecords,
                  params.pageSize
                );
                setPages(pages);
              }
              if (res && !res.data.list.length) {
                onPageChangeHandler(params.pageNo - 1);
              }
            });
            getUserList();
          } else {
            if (res) {
              swal(res.message, {
                icon: "danger"
              });
            }
          }
        });
      }
    });
  };

  //Sort Handler
  const onSortChange = sortProperties => {
    const orderByDirection = sortProperties[0].desc ? 1 : 0;
    if (!orderByDirection) {
      let data = {
        ...params,
        orderBy: sortProperties[0].id,
        orderSequence: "ASC"
      };
      setParams(data);
      viewAllUsers(data).then(res => {
        if (res && res.status) {
          settblData(res.data.list);
          setLoader(false);
        }
      });
    } else {
      let data = {
        ...params,
        orderSequence: "DESC"
      };
      setParams(data);
      viewAllUsers(data).then(res => {
        if (res && res.status) {
          settblData(res.data.list);
          setLoader(false);
        }
      });
    }
  };


  //Columns of the Table
  const columns = React.useMemo(
    () => [
      {
        Header: "User name",
        accessor: "userName",
        className: "text-center",
        filterable: false,
        headerClassName: "react-table-header-class -sort"
      },
      {
        Header: "Email",
        accessor: "email",
        className: "text-center",
        filterable: false,
        sortable: false,
        headerClassName: "react-table-header-class"
      },
      {
        Header: "User Created",
        accessor: "createdAt",
        className: "text-center",
        filterable: false,
        sortable: true,
        headerClassName: "react-table-header-class -sort",
        Cell: props => {
          return `${new Date(props.original.createdAt).getMonth() + 1}/${new Date(
            props.original.createdAt
          ).getDate()}/${new Date(props.original.createdAt).getFullYear()}`;
        },
        width: 180
      },
      {
        Header: "Status",
        accessor: "status",
        className: "text-center",
        headerClassName: "react-table-header-class",
        filterable: false,
        sortable: false,
        width: 180,
        Cell: props => (
          <div className="react-action-class">
            {props.original.status === "active" ? (
              <Fragment>
                <div id={`active-${props.original.id}`}>
                  <Button
                    style={{ minWidth: "125px" }}
                    className="c-btn c-info mr-10"
                    onClick={() =>
                      activeInactiveStatusHandler(props.original.id)
                    }>
                    <div className="fs-14 medium-text">
                      <i className="fas fa-toggle-off mr-6" /> Active
                    </div>
                  </Button>
                </div>
                <RoyTooltip
                  id={`active-${props.original.id}`}
                  title={"Click to Deactivate"}
                  placement="left"></RoyTooltip>
              </Fragment>
            ) : (
                <RoyTooltip
                  id={`deactive-${props.original.id}`}
                  title={"Click to Active"}
                  placement="left">
                  <div id={`deactive-${props.original.id}`}>
                    <Button
                      style={{ minWidth: "125px" }}
                      className="c-btn c-warning mr-10"
                      onClick={() =>
                        activeInactiveStatusHandler(props.original.id)
                      }>
                      <div className="fs-14 medium-text">
                        <i className="fas fa-toggle-on mr-6" /> InActive
                    </div>
                    </Button>
                  </div>
                </RoyTooltip>
              )}
          </div>
        ) // Custom cell components!
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        headerClassName: "react-table-header-class",
        sortable: false,
        filterable: false,
        width: 250,
        Cell: props => (
          <div className="react-action-class">
            <Button
              className="c-btn c-success mr-10"
              onClick={() => formAction("edit", props.original)}>
              <div className="fs-14 medium-text">
                <i className="fas fa-edit mr-6" /> Edit
              </div>
            </Button>
            <Button
              className="c-btn c-danger"
              onClick={() => deletePage(props.original.id)}>
              <div className="fs-14 medium-text">
                <i className="fas fa-trash mr-6" /> Delete
              </div>
            </Button>
          </div>
        ) // Custom cell components!
      }
    ],
    [params]
  );

  return (
    <React.Fragment>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              {/* <span className="hash"># </span> Users */}
            </div>
            <div className="mr-10">
              <input
                value={searchText || ""}
                onChange={e => setSearchText(e.target.value)}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            <div>
              <Button
                className="c-btn ma-5"
                onClick={() => formAction("add")}
                style={{
                  backgroundColor: activeColor,
                  color: textColor
                }}>
                <i className="fas fa-plus mr-10"></i>Add User
              </Button>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none"
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={2}
              minRows={0}
              pages={pages}
              filterable={false}
              manual
              className="-striped -highlight custom-react-table-theme-class"
              page={activePage - 1}
              PaginationComponent={Pagination}
              loading={loader}
              onPageChange={pageIndex => onPageChangeHandler(pageIndex)}
              onSortedChange={sortProperties => onSortChange(sortProperties)}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserTable);
