import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Card = props => {
  const Wrapper = styled.div`
    border: 2px solid ${props.bgColor};
    margin: 10px;
    margin-top: 20px;
    border-radius: 6px;
    background-color: ${props.bgColor};
    display: flex;
    flex-flow: column;
    box-sizing: border-box;
    text-align: center;
    height: 80px;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    transition-property: box-shadow;
    transition-duration: 0.6s;
    a:hover {
      color: whitesmoke !important;
      text-decoration: none !important;
    }

    .count {
      font-style: bold;
      color: whitesmoke;
      font-size: 24px;
      align-self: center;
    }
    .title {
      font-size: 20px;
      align-self: center;
      color: whitesmoke;
    }
    &:hover {
      box-shadow: 0 3px 12px ${props.bgColor || "rgb(114, 102, 186)"};
      cursor: pointer;
      color: whitesmoke;
      text-decoration: none !important;
    }
  `;
  return (
    <NavLink to={props.to}>
      <Wrapper>
        <div className="count">{props.count}</div>
        <div className="title">{props.title}</div>
      </Wrapper>
    </NavLink>
  );
};

export default Card;
