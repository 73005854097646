import React, { useState, Fragment } from "react";
import enhancer from "./validator";
import CustomToast from "../customToast/CustomToast";

const UserForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action
  } = props;

  const [toast, showToast] = useState(false);
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const handleSubmit = () => {
    props.handleSubmit();
    if (action === "edit") {
      const err = Object.getOwnPropertyNames(props.errors);
      if (props.isValid || !err.length) {
        let { values } = props;
        onSubmit(values, action);
      }
    }
    if (action === "add") {
      if (props.isValid) {
        let { values } = props;
        onSubmit(values, action);
      }
    }
  };

  return (
    <Fragment>
      {toast && (
        <CustomToast
          heading={"Alert!"}
          width={400}
          show="bottomRight"
          transition
          position="bottom-right"
          className="c-danger"
          message="You haven't changed Anything"
          onCloseCLick={() => showToast(false)}
        />
      )}
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">User Name</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="userName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.userName}
            placeholder="User Name"
          />
          <Error field="userName" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">Email</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            placeholder="Enter Email"
          />
          <Error field="email" />
        </div>
        <div>
          <button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default enhancer(UserForm);
