import React from "react";
import CompanyWrapper from "../UserModule/user.style";
import PageTitle from "components/common/PageTitle";
import SACFromSACTable from "../../components/subadmincompanyModule/SACFromSACTable";
import { connect } from "react-redux";

const SACFromSAC = (props) => {
  // console.log(props);
  return (
    <CompanyWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          title="Company Sub Admin's"
          className="plr-15"
          iconClass="fas fa-building"
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/dashboard")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="user-tbl">
        <SACFromSACTable {...props} />
      </div>
    </CompanyWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
  };
};

export default connect(mapStateToProps, null)(SACFromSAC);
