import React, { useEffect, useState } from "react";
import CompanyWrapper from "../UserModule/user.style";
import PageTitle from "components/common/PageTitle";
import SubAdminComapnyForm from "../../components/subadmincompanyModule/subadmincompanyForm";
import { connect } from "react-redux";
import { addSubAdminCompany, editSubAdminCompany } from "../../api/index";
import CustomToast from "../../components/customToast/CustomToast";

const SubAdminCompanyFromAdminEdit = (props) => {
  const hasCompany_id = props.match.params.hasOwnProperty("company_id");
  let company_id_number;
  if (hasCompany_id) {
    company_id_number = props.match.params.company_id;
  }

  const [editedData, setEditedData] = useState(props.location.state);
  const [toast, showToast] = useState(false);
  const [toastMsg, setMsg] = useState("");

  const hasParam = props.match.params.hasOwnProperty("id");

  useEffect(() => {
    if (hasParam) {
      const data = {
        ...props.location.state,
      };
      setEditedData(data);
    }
  }, []);

  const submitFormHandler = (data, action) => {
    if (action === "add") {
      let apiData = {
        ...data,
      };
      addSubAdminCompany(apiData, company_id_number).then((res) => {
        if (res && res.status) {
          if (company_id_number === undefined) {
            props.history.push("/subadmincompaniesfromsac");
          } else {
            props.history.push(
              `/subadmincompaniesfromadmin/${company_id_number}`
            );
          }
        } else {
          if (res) {
            setMsg(res.message);
            showToast(true);
          }
        }
      });
    } else if (action === "edit") {
      let apiData = {
        id: props.location.state.id,
        name: data.name,
        email: data.email,
        address: data.address,
        description: data.description,
        link: data.link,
        logo: data.logo,
      };
      editSubAdminCompany(apiData, company_id_number).then((res) => {
        if (res && res.status) {
          if (company_id_number === undefined) {
            props.history.push("/subadmincompaniesfromsac");
          } else {
            props.history.push(
              `/subadmincompaniesfromadmin/${company_id_number}`
            );
          }
        } else {
          if (res) {
            setMsg(res.message);
            showToast(true);
          }
        }
      });
    }
  };

  return (
    <CompanyWrapper {...props}>
      {toast && (
        <CustomToast
          heading={"Alert!"}
          width={400}
          show="bottomRight"
          transition
          position="bottom-right"
          className="c-danger"
          message={toastMsg}
          onCloseCLick={() => showToast(false)}
        />
      )}
      <div className="pos-relative">
        <PageTitle
          title="Company Sub Admin's"
          className="plr-15"
          iconClass="fas fa-edit"
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() =>
            props.history.push(
              `/subadmincompaniesfromadmin/${company_id_number}`
            )
          }
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              {/* <span className="hash"># </span> {hasParam ? "Edit" : "Add"} Sub
              Admin Company */}
            </div>
          </div>
          <div className="roe-card-body">
            <SubAdminComapnyForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              action={hasParam ? "edit" : "add"}
            />
          </div>
        </div>
      </div>
    </CompanyWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
  };
};

export default connect(mapStateToProps, null)(SubAdminCompanyFromAdminEdit);
