import styled from 'styled-components'

const DashboardWrapper = styled.div `
    background-color: #ffffff;
    border-radius:4px;
    border:2px solid white;
    height:440px;
    margin-left:15px;
    margin-right:15px;

    a:hover{
        color:whitesmoke;
        text-decoration:none;
    }
    

`

export default DashboardWrapper;