import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string().required("This field is required"),
    email: Yup.string()
      .required("This Field is required")
      .email("Please Enter Valid Email"),
    address: Yup.string().required("This Field is required"),
  }),

  // isInitialValid: true,
  mapPropsToValues: (props) => ({
    name: props.editedData ? props.editedData.name : "",
    email: props.editedData ? props.editedData.email : "",
    isComplex: props.editedData ? props.editedData.isComplex : "",
    address: props.editedData ? props.editedData.address : "",
    description: props.editedData ? props.editedData.description : "",
    link: props.editedData ? props.editedData.link : "",
    logo: props.editedData ? props.editedData.logo : "",
  }),
  handleSubmit: (values) => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
